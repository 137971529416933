import { Box, SxProps } from '@mui/material';
import { ReactNode } from 'react';
import { StyleObj } from '../../@types';
import { mergeSx } from '../../utils/styles';

const styles: StyleObj = {
  container: {
    position: 'relative',
    width: '100%',
    overflow: 'hidden',
    maskImage: 'linear-gradient(to bottom, black 60%, transparent 100%)',
    WebkitMaskImage: 'linear-gradient(to bottom, black 60%, transparent 100%)',
  },
};

type SkeletonContainerProps = {
  children: ReactNode;
  sx?: SxProps;
};

const SkeletonContainer = ({ children, sx }: SkeletonContainerProps) => {
  return <Box sx={mergeSx(styles.container, sx)}>{children}</Box>;
};

export default SkeletonContainer;
