import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { StyleObj } from '../../@types';
import { getEventDetailLink } from '../../helpers/navigation';

type TotalMarketsLinkProps = {
  eventId: string;
  totalMarkets: number;
  inPlay?: boolean;
};

const styles: StyleObj = {
  topMarketLink: {
    textDecoration: 'none',
    '&:hover': {
      color: 'white',
    },
    typography: {
      xs: 'body4',
      md: 'body3',
    },
    color: 'neutral.300',
  },
};

const TotalMarketsLink = ({ eventId, totalMarkets, inPlay }: TotalMarketsLinkProps) => {
  return (
    <Typography component={Link} to={getEventDetailLink(eventId, inPlay)} sx={styles.topMarketLink}>
      {totalMarkets} &gt;
    </Typography>
  );
};

export default TotalMarketsLink;
