import { PublishedEventListItem } from '../../../@types';

type GroupedEvents = {
  [tournamentId: string]: PublishedEventListItem[];
};

export const groupFeaturedTournaments = (events: PublishedEventListItem[]) => {
  return events.reduce((acc: GroupedEvents, event) => {
    const { tournamentId } = event;

    if (!acc[tournamentId]) {
      acc[tournamentId] = [];
    }

    acc[tournamentId].push(event);
    return acc;
  }, {});
};
