import { Stack } from '@mui/material';
import Heading from '../atoms/Heading';
import EventsTable from '../organisms/tables/EventsTable';
import { QUERY_KEYS } from '../../constants';
import { usePublishedEvents } from '../../queries';
import useLocalization from '../../hooks/useLocalization';
import FeaturedTableSkeleton from './skeletons/FeaturedTableSkeleton';

type Props = {
  sportId?: string;
  sportName?: string;
};

const UpcomingEventsTable = ({ sportId, sportName }: Props) => {
  const { t, getLocalizedSportName } = useLocalization();

  const { data: upcomingEventsData, isLoading } = usePublishedEvents({
    queryKey: [QUERY_KEYS.upcomingMatches],
    queryParams: {
      limit: 10,
      sportId,
      sort: 'startDate',
      count: false,
    },
    options: {
      enabled: !!sportId,
    },
  });

  if (isLoading || !sportId) {
    return <FeaturedTableSkeleton />;
  }

  return (
    <>
      <Heading spacing={0.5} direction='column' alignItems='start' to={`${sportId}/upcoming-matches/all-matches`}>
        <Heading.Subtitle>{getLocalizedSportName(sportName)}</Heading.Subtitle>
        <Stack direction='row' alignItems='center' justifyContent='space-between' width={1}>
          <Heading.Title>{t('upcomingMatches')}</Heading.Title>
          <Heading.Link />
        </Stack>
      </Heading>
      {!!upcomingEventsData?.items && sportId && (
        <EventsTable
          data={upcomingEventsData?.items}
          showTableHead
          topMarketTypes={upcomingEventsData?.topMarketTypes}
          outcomeTypes={upcomingEventsData?.topMarketTypes?.[0]?.outcomeTypes}
        />
      )}
    </>
  );
};

export default UpcomingEventsTable;
