import { useState, useCallback, useContext, createContext, ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

type MiniStatsContextType = {
  activeWidgetId: string | null;
  setActiveWidgetId: (id: string | null) => void;
};

const MiniStatsContext = createContext<MiniStatsContextType | undefined>(undefined);

export const MiniStatsProvider = ({ children }: { children: ReactNode }) => {
  const [activeWidgetId, setActiveWidgetId] = useState<string | null>(null);

  return (
    <MiniStatsContext.Provider value={{ activeWidgetId, setActiveWidgetId }}>{children}</MiniStatsContext.Provider>
  );
};

export const useMiniStatsWidget = (widgetId: string | null) => {
  const context = useContext(MiniStatsContext);
  const { pathname } = useLocation();

  if (!context) {
    throw new Error('useMiniStatsWidget must be used within a MiniStatsProvider');
  }

  const { activeWidgetId, setActiveWidgetId } = context;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const isOpen = activeWidgetId === widgetId;

  useEffect(() => {
    setActiveWidgetId(null);
  }, [pathname, setActiveWidgetId]);

  const toggle = useCallback(
    (element: HTMLElement | null) => {
      if (isOpen) {
        setActiveWidgetId(null);
        setAnchorEl(null);
      } else {
        setActiveWidgetId(widgetId);
        setAnchorEl(element);
      }
    },
    [isOpen, widgetId, setActiveWidgetId]
  );

  return {
    isOpen,
    anchorEl,
    toggle,
  };
};
