import { OddsFormatType } from '../@types';
import { ODDS_FORMAT } from '../constants/odds';

export const decimalToAmerican = (decimal: number) => {
  if (decimal <= 1) return '-';

  if (decimal >= 2) {
    return `+${Math.round((decimal - 1) * 100)}`;
  } else {
    return Math.round(-100 / (decimal - 1)).toString();
  }
};

const greatestCommonDivisor = (a: number, b: number): number => {
  return b === 0 ? a : greatestCommonDivisor(b, a % b);
};

export const decimalToFractional = (decimal: number) => {
  if (decimal <= 1) return '-';

  let numerator = Math.round((decimal - 1) * 100);
  let denominator = 100;

  const divisor = greatestCommonDivisor(numerator, denominator);
  numerator = Math.round(numerator / divisor);
  denominator = Math.round(denominator / divisor);

  return `${numerator}/${denominator}`;
};

export const formatOdds = (decimal: number, format: OddsFormatType) => {
  if (isNaN(decimal) || decimal <= 1) {
    return '-';
  }

  switch (format) {
    case ODDS_FORMAT.AMERICAN:
      return decimalToAmerican(decimal);
    case ODDS_FORMAT.FRACTIONAL:
      return decimalToFractional(decimal);
    default:
      return decimal.toFixed(2);
  }
};
