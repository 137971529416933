import { Tab, Tabs } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CasinoIcon from '../../assets/icons/navigation/CasinoIcon';
import LiveIcon from '../../assets/icons/navigation/LiveIcon';
import SportsIcon from '../../assets/icons/navigation/SportsIcon';
import useLocalization from '../../hooks/useLocalization';

const TABS = [
  { label: 'sports', path: '/sports', icon: <SportsIcon /> },
  { label: 'mobileLive', path: '/in-play', icon: <LiveIcon /> },
  { label: 'casino', path: '/casino', icon: <CasinoIcon /> },
];

const styles = {
  tabs: {
    minHeight: 72,
    '& .MuiTab-root': {
      color: 'neutral.50',
      p: 1,
      textTransform: 'none',
      typography: {
        xs: 'subtitle3',
        md: 'h5',
      },
      display: 'flex',
      flexDirection: 'column',
      '&.Mui-selected': {
        color: 'info.main',
      },
    },
    '& .MuiSvgIcon-root': {
      fontSize: {
        xs: 24,
        md: 32,
      },
    },
    '& .MuiTabs-indicator': {
      display: 'none',
    },
  },
};

const SidebarTabs = ({ onDrawerClose }: { onDrawerClose: () => void }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { getLocalizedConfig } = useLocalization();
  const localizedTabs = useMemo(() => getLocalizedConfig(TABS), [getLocalizedConfig]);

  const defaultValue = TABS.findIndex((tab) => location.pathname.includes(tab.path));
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(TABS.findIndex((tab) => location.pathname.startsWith(tab.path)) ?? 0);
  }, [location.pathname]);

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    navigate(TABS[newValue].path);
    onDrawerClose();
  };

  return (
    <Tabs value={value} onChange={handleChange} sx={styles.tabs} variant='fullWidth'>
      {localizedTabs.map((tab, index) => (
        <Tab key={tab.path} value={index} icon={tab.icon} iconPosition='top' label={tab.label} />
      ))}
    </Tabs>
  );
};

export default SidebarTabs;
