import { useEffect, useState } from 'react';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { useUserSettings } from '../queries';
import { useSanity } from './useSanity';
import { SanityImage } from '../@types';
import { getSanityImage } from '../helpers/sanity';
import { useLocation } from 'react-router-dom';
import { preventPWA } from './useManifestLoad';

const localesMap: Record<string, string> = {
  'en-US': 'en',
  en: 'en',
  sr: 'sr',
  sq: 'sq',
};

const baseURL = 'https://cdn.jsdelivr.net/gh/philfung/add-to-homescreen@3.0/dist';

const useFullScreenHelper = () => {
  const { data: userData } = useUserSettings();
  const location = useLocation();
  const [loaded, setLoaded] = useState(!!window.AddToHomeScreenInstance);
  const { data: logo } = useSanity<{ image: SanityImage }>('Logo');
  const sanitySrc = getSanityImage(logo?.[0]?.image);
  const config = useFeatureValue('full-screen-config', null) as {
    maxModalDisplayCount: number;
    paths: string[];
    restrictedDomains: string[];
  } | null;

  const pathName = location.pathname;

  useEffect(() => {
    if (!preventPWA(config?.restrictedDomains)) {
      const locale = userData?.language ?? 'en';
      setLoaded(false);
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = `${baseURL}/add-to-homescreen.min.css`;
      document.head.appendChild(link);

      const script = document.createElement('script');
      script.defer = true;
      script.src = `${baseURL}/add-to-homescreen_${localesMap[locale]}.min.js`;
      script.onload = () => setLoaded(true);
      script.onerror = () => console.error('Failed to load script:', script.src);

      document.head.appendChild(script);

      return () => {
        document.head.removeChild(link);
        document.head.removeChild(script);
      };
    }
  }, [userData?.language, setLoaded]);

  useEffect(() => {
    const createInstance = () => {
      if (window.AddToHomeScreen) {
        window.AddToHomeScreenInstance = window.AddToHomeScreen({
          appName: 'Sportsbook',
          appIconUrl: sanitySrc,
          maxModalDisplayCount: config?.maxModalDisplayCount ?? -1,
          assetUrl: `${baseURL}/assets/img/`,
        });
      }
    };
    if (loaded && sanitySrc) {
      createInstance();
    }
  }, [loaded, sanitySrc]);

  useEffect(() => {
    if (!config || !loaded || preventPWA(config?.restrictedDomains)) {
      return;
    }
    const locale = userData?.language ?? 'en';
    const pathMatch = config?.paths?.some((path) => pathName.includes(path));
    const mounted = !!document.getElementsByClassName('adhs-container')?.length;

    const displayUI = pathMatch && !mounted;
    if (displayUI) {
      try {
        window.AddToHomeScreenInstance?.show(localesMap[locale] ?? 'en');
      } catch (err) {
        console.error('Error mounting full screen helpper!');
      }
    }
  }, [pathName, config, loaded]);
};

export default useFullScreenHelper;
