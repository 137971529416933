import { useCallback } from 'react';
import { ODDS_FORMAT } from '../constants/odds';
import { useUserSettings } from '../queries';
import { formatOdds } from '../utils/oddsConverter';

const oddsCache = new Map<string, string>();

const useOddsFormat = () => {
  const { data: userSettings } = useUserSettings();

  const selectedFormat = userSettings?.oddsFormat || ODDS_FORMAT.DECIMAL;

  const formatOddsValue = useCallback(
    (decimalOdds: string | number) => {
      const numericOdds = typeof decimalOdds === 'string' ? parseFloat(decimalOdds) : decimalOdds;

      const cacheKey = `${numericOdds}-${selectedFormat}`;

      if (oddsCache.has(cacheKey)) {
        const cachedValue = oddsCache.get(cacheKey);
        if (cachedValue !== undefined) {
          return cachedValue;
        }
      }

      const result = formatOdds(numericOdds, selectedFormat);
      oddsCache.set(cacheKey, result);

      return result;
    },
    [selectedFormat]
  );

  return { formatOddsValue, selectedFormat };
};

export default useOddsFormat;
