const CopyIdIcon = () => {
  return (
    <svg
      strokeWidth='0.5'
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='currentColor'
    >
      <path d='M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2m0 16H8V7h11z' />
      <text x='14' y='19' fontSize='7' fontWeight='bold' fill='currentColor' textAnchor='middle'>
        ID
      </text>
    </svg>
  );
};

export default CopyIdIcon;
