import { Box } from '@mui/material';
import StatscoreWidget from './StatScoreWidget';

const styles = {
  container: {
    width: { xs: '100%', md: 840, lg: 1024 },
    height: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
};

type StatScoreModalProps = {
  eventId: string;
  configurationId: string;
};

const StatScoreModal = ({ eventId, configurationId }: StatScoreModalProps) => {
  return (
    <Box sx={styles.container}>
      <StatscoreWidget eventId={eventId} configurationId={configurationId} />
    </Box>
  );
};

export default StatScoreModal;
