import { getSidebarType, SIDEBAR_OPTION, SidebarType } from '../../../config/sidebar';
import BettingDrawerContent from './BettingDrawerContent';
import DrawerLayout from '../../layouts/DrawerLayout';
import InPlayMatchSidebar from './InPlayMatchSidebar';
import { useLocation } from 'react-router-dom';
import AccountDrawerContent from './AccountDrawerContent';
import InPlaySportSidebar from './InPlaySportSidebar';
import useBreakpoints from '../../../hooks/useBreakpoints';

type DrawerNavigationProps = {
  isOpen: boolean;
  toggleVisibility: (isOpen: boolean) => void;
};

const SIDEBAR_COMPONENTS: Record<SidebarType, React.ComponentType<{ toggleVisibility: (isOpen: boolean) => void }>> = {
  [SIDEBAR_OPTION.SPORTS]: BettingDrawerContent,
  [SIDEBAR_OPTION.IN_PLAY]: InPlaySportSidebar,
  [SIDEBAR_OPTION.IN_PLAY_MATCH]: InPlayMatchSidebar,
  [SIDEBAR_OPTION.ACCOUNT]: AccountDrawerContent,
};

const DrawerNavigation = ({ isOpen, toggleVisibility }: DrawerNavigationProps) => {
  const location = useLocation();
  const { isMobile } = useBreakpoints();
  const sidebarType = getSidebarType(location.pathname, isMobile);

  if (!sidebarType) return null;

  const DrawerContent = SIDEBAR_COMPONENTS[sidebarType];

  return (
    <DrawerLayout isOpen={isOpen} toggleVisibility={toggleVisibility} sidebarType={sidebarType}>
      <DrawerContent toggleVisibility={toggleVisibility} />
    </DrawerLayout>
  );
};

export default DrawerNavigation;
