const CasinoIcon = () => {
  return (
    <svg height={40} width={40} fill='currentColor' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40'>
      <defs></defs>
      <path d='M31.59,10.18h-27a1.24,1.24,0,0,0-1.31,1.15V29.48a1.26,1.26,0,0,0,1.35,1.15h27A1.27,1.27,0,0,0,33,29.48V11.33a1.24,1.24,0,0,0-1.33-1.15ZM23.5,11.33h8.09v1.15H23.5Zm-18.88,0h8.1v1.15H4.62Zm0,18.15V13.62h8.1V29.48Zm17.54,0H14.07V28.33h8.09Zm0-2.3H14.07V11.33h8.09Zm1.34,2.3V13.62h8.09V29.48Z'></path>
      <path d='M36.73,10.82a1.44,1.44,0,1,0-2,1.32v7.5a.57.57,0,0,1-.55.57H33v1.15h1.15a1.72,1.72,0,0,0,1.72-1.72h0v-7.5A1.47,1.47,0,0,0,36.73,10.82Z'></path>
      <path d='M11.91,17.89,9.53,25.1a.3.3,0,0,1-.18.2,1,1,0,0,1-.43.05,3.41,3.41,0,0,1-1.23-.2c-.37-.13-.56-.28-.56-.44a.33.33,0,0,1,0-.14l2.19-4.18a1.77,1.77,0,0,0,.24-.64c0-.15-.1-.25-.28-.31a4.77,4.77,0,0,0-1-.08,5.72,5.72,0,0,0-1.24.1.58.58,0,0,0-.29.22,1.18,1.18,0,0,1-.29.3.62.62,0,0,1-.35.08.68.68,0,0,1-.62-.33,1.9,1.9,0,0,1-.21-1,2.8,2.8,0,0,1,.28-1.27A1.18,1.18,0,0,1,5.91,17a.66.66,0,0,1,.4-.17,2.14,2.14,0,0,1,.6.21,1.76,1.76,0,0,0,.72.12H11.4c.37,0,.56.14.56.42A.76.76,0,0,1,11.91,17.89Z'></path>
      <path d='M30.8,17.89,28.43,25.1a.31.31,0,0,1-.19.2,1,1,0,0,1-.43.05,3.41,3.41,0,0,1-1.23-.2c-.37-.13-.55-.28-.55-.44a.24.24,0,0,1,0-.14l2.18-4.18a1.77,1.77,0,0,0,.24-.64c0-.15-.09-.25-.27-.31a4.77,4.77,0,0,0-1-.08,5.81,5.81,0,0,0-1.25.1.58.58,0,0,0-.29.22,1,1,0,0,1-.3.3.57.57,0,0,1-.34.08.68.68,0,0,1-.62-.33,1.73,1.73,0,0,1-.22-1,2.79,2.79,0,0,1,.24-1.27A1.57,1.57,0,0,1,24.8,17a.69.69,0,0,1,.41-.17,2,2,0,0,1,.59.21,1.81,1.81,0,0,0,.73.12h3.76c.38,0,.56.14.56.42A.59.59,0,0,1,30.8,17.89Z'></path>
      <path d='M21.41,16,19,23.2a.29.29,0,0,1-.19.2,1,1,0,0,1-.43,0,3.44,3.44,0,0,1-1.23-.19c-.37-.14-.55-.28-.55-.44a.44.44,0,0,1,.05-.15l2.18-4.16a1.77,1.77,0,0,0,.24-.64c0-.15-.09-.25-.27-.31a4.77,4.77,0,0,0-1-.08,5.41,5.41,0,0,0-1.27.11.64.64,0,0,0-.29.22,1.11,1.11,0,0,1-.3.31.58.58,0,0,1-.34.07.68.68,0,0,1-.62-.33,1.73,1.73,0,0,1-.22-1,2.84,2.84,0,0,1,.28-1.31,1.57,1.57,0,0,1,.35-.42.74.74,0,0,1,.41-.16,2,2,0,0,1,.59.2,1.81,1.81,0,0,0,.73.12h3.77c.37,0,.55.14.55.42A1,1,0,0,1,21.41,16Z'></path>
    </svg>
  );
};

export default CasinoIcon;
