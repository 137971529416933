import { extractSpecialValuesFromOutcomeName } from '../helpers';
import { SpecialValueFormatterContext } from '../types/specialValues';

export type SpecialValueFormatter = (
  value: string | undefined,
  context: SpecialValueFormatterContext
) => string | undefined;

export const handicapFormatter: SpecialValueFormatter = (value, { marketTypeName, outcomeName, specialValues }) => {
  if (!marketTypeName?.toLowerCase().includes('handicap')) {
    return value;
  }

  const specialValuesFromOutcomeName = extractSpecialValuesFromOutcomeName(
    outcomeName,
    specialValues,
    true,
    marketTypeName
  );

  if (!specialValuesFromOutcomeName) {
    return value;
  }

  return specialValuesFromOutcomeName.join(' ');
};

export const defaultSpecialValuesFormatter: SpecialValueFormatter = (value, { specialValues, numericOnly }) => {
  if (value) {
    return value;
  }

  const displayValue = specialValues?.map((specialValue) => specialValue.value).join(' ') || '';

  if (numericOnly && displayValue) {
    const numericMatch = displayValue.match(/([-+]?\d*\.?\d+)\s*$/);

    return numericMatch?.[0] || displayValue;
  }

  return displayValue;
};

export const matchbetAndTotalsFormatter: SpecialValueFormatter = (value, { marketTypeName, outcomeName, sportId }) => {
  const isSoccer = sportId === '1';

  if (!isSoccer || !marketTypeName?.toLowerCase().includes('matchbet and totals') || !value) {
    return value;
  }

  const numericValue = parseFloat(value);
  if (isNaN(numericValue)) return value;

  const isOver = outcomeName.toLowerCase().includes('over');
  const isUnder = outcomeName.toLowerCase().includes('under');

  if (isOver) {
    const floorValue = Math.floor(numericValue);
    return `${floorValue + 1}+`;
  } else if (isUnder) {
    const ceilValue = Math.ceil(numericValue);
    return `0-${ceilValue - 1}`;
  }

  return value;
};

export const parenthesisFormatter: SpecialValueFormatter = (value, { wrapInParenthesis }) => {
  if (!value) return value;

  return wrapInParenthesis ? ` (${value})` : ` ${value}`;
};

export const localizationFormatter: SpecialValueFormatter = (value, { marketTypeName, t }) => {
  if (!value || !marketTypeName?.toLowerCase().includes('handicap')) return value;

  return value.replace(/(\(W\))|(\bW\b)/g, t('w', { ns: 'outcomes' }));
};

export const playerTeamNameFormatter: SpecialValueFormatter = (value, { specialValueModel }) => {
  if (specialValueModel?.modelType === 'player' && specialValueModel.playerTeamName) {
    const prefix = value ? value : '';
    return `${prefix} (${specialValueModel.playerTeamName})`;
  }

  return value;
};

export const formatters: SpecialValueFormatter[] = [
  handicapFormatter,
  defaultSpecialValuesFormatter,
  matchbetAndTotalsFormatter,
  parenthesisFormatter,
  localizationFormatter,
  playerTeamNameFormatter,
];

export const formatSpecialValue = (context: SpecialValueFormatterContext, value?: string) => {
  return formatters.reduce((currentValue, formatter) => formatter(currentValue, context), value);
};
