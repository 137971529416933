import { Backdrop, CircularProgress, Stack } from '@mui/material';
import { useMarketplaceConfig } from '../../queries/marketplace';
import { Outlet } from 'react-router-dom';
import { useState, useEffect } from 'react';
import MaintenancePage from '../pages/MaintenancePage';
import Header from '../molecules/Header';
import useFullScreenHelper from '../../hooks/useFullScreenHelper';

export const TRIGGER_MAINTENANCE_MODE_EVENT = 'triggerMaintenance';

const MarketplaceLayout = () => {
  const [isMaintenanceMode, setIsMaintenanceMode] = useState(false);
  const { isLoading, data: marketPlaceData } = useMarketplaceConfig();
  useFullScreenHelper();

  useEffect(() => {
    setIsMaintenanceMode(marketPlaceData?.feedRunning === false);

    const handleMaintenanceMode = () => {
      setIsMaintenanceMode(true);
    };

    window.addEventListener(TRIGGER_MAINTENANCE_MODE_EVENT, handleMaintenanceMode);

    return () => {
      window.removeEventListener(TRIGGER_MAINTENANCE_MODE_EVENT, handleMaintenanceMode);
    };
  }, [marketPlaceData?.feedRunning]);

  if (isLoading) {
    return (
      <Backdrop open={isLoading}>
        <CircularProgress />
      </Backdrop>
    );
  }

  return isMaintenanceMode ? (
    <Stack>
      <Header />
      <MaintenancePage />
    </Stack>
  ) : (
    <Outlet />
  );
};

export default MarketplaceLayout;
