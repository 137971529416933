import { Box, Divider, LinearProgress, List, ListItem, ListItemText } from '@mui/material';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { StyleObj } from '../../@types';
import { SearchEvent } from '../../@types/search';
import { useSearchContext } from '../../contexts/SearchContext';
import useLocalization from '../../hooks/useLocalization';
import EmptyStateMessage from './EmptyStateMessage';
import { dynamicViewportHeight } from '../../styles/mixins';

interface SearchTabPanelProps {
  value?: string;
  events?: SearchEvent[];
}

const formatDate = (date: string) => dayjs(date).format('ddd, DD MMM HH:mm');

const styles: StyleObj = {
  list: {
    pt: {
      xs: 0,
      md: 1.5,
    },
    pb: 16,
    mt: 1.5,
    ...dynamicViewportHeight(),
    overflowY: 'auto',
    scrollbarWidth: 'none',
  },
  listItemWrapper: {
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
  },
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '&:hover .hover-target': {
      color: 'info.300',
    },
    px: 0,
    py: {
      xs: 1,
      md: 1.5,
    },
  },
  mainListItemText: {
    mb: {
      xs: 1,
      md: 2,
    },
    '&:hover': { color: 'info.300' },
    '& .MuiTypography-root': {
      typography: {
        xs: 'h5',
        md: 'h3',
      },
    },
  },
  listItemText: {
    my: 0.25,
  },
  divider: {
    background: 'white',
    height: '1px',
    opacity: '0.2',
  },
  liveEventBadge: {
    backgroundColor: 'secondary.main',
    color: 'neutral.800',
    borderRadius: 1,
    px: 1,
    py: 0.125,
    typography: {
      xs: 'body4',
      md: 'h5',
    },
    fontWeight: {
      xs: 700,
      md: 700,
    },
    textTransform: 'uppercase',
    mr: 1,
  },
};

const isLiveMatchEvent = (event: SearchEvent) => event.isLive && event.type === 'match';

const SearchTabPanel = ({ value, events }: SearchTabPanelProps) => {
  const navigate = useNavigate();
  const { handleClear, isLoading, hasNoResults } = useSearchContext();
  const { t } = useLocalization();

  const handleClick = (searchEvent: SearchEvent) => {
    handleClear();

    if (searchEvent.type === 'outright') {
      navigate(`/sports/${value}/tournaments/${searchEvent.id}/outrights`);
    } else if (searchEvent.isLive) {
      navigate(`/in-play/match-view/${searchEvent.id}`);
    } else {
      navigate(`/sports/events/${searchEvent.id}`);
    }
  };

  return (
    <List sx={styles.list}>
      {isLoading && <LinearProgress sx={{ width: '100%' }} />}
      {hasNoResults && <EmptyStateMessage>{t('noSearchResults')}</EmptyStateMessage>}
      {events?.map((event) => (
        <Box key={event.id} sx={styles.listItemWrapper} onClick={() => handleClick(event)}>
          <ListItem key={event.id} sx={styles.listItem}>
            <ListItemText
              primary={
                <>
                  {isLiveMatchEvent(event) && (
                    <Box component='span' sx={styles.liveEventBadge}>
                      • {t('mobileLive')}
                    </Box>
                  )}
                  {event.name}
                </>
              }
              sx={styles.mainListItemText}
            />
            <ListItemText
              primary={`${event.competition.name}, ${event.tournament.name}`}
              primaryTypographyProps={{
                typography: {
                  xs: 'body4',
                  md: 'body3',
                },
              }}
              sx={styles.listItemText}
            />
            <ListItemText
              primary={formatDate(event.startDate)}
              primaryTypographyProps={{
                typography: {
                  xs: 'body4',
                  md: 'body3',
                },
              }}
              sx={styles.listItemText}
            />
          </ListItem>
          <Divider sx={styles.divider} />
        </Box>
      ))}
    </List>
  );
};

export default SearchTabPanel;
