import { TableContainer, Paper, TableBody, TableRow, TableCell, TableHead } from '@mui/material';
import MuiTable from '@mui/material/Table';
import { Market, Outcome, PublishedEventParticipants, StyleObj } from '../../../@types';
import { getOutcomesByParticipantsWithLength } from '../../../helpers';
import OutcomeTableCell from '../../atoms/OutcomeTableCell';
import { getColumnStyle } from '../../../utils';

const styles: StyleObj = {
  tableContainer: {
    width: '100%',
  },
  table: {
    tableLayout: 'fixed',
    width: '100%',
  },
  tableRow: {
    display: 'flex',
    borderTop: '1px solid',
    borderColor: 'neutral.600',
  },
  tableCell: {
    width: '50%',
  },
  head: {
    '& .MuiTableRow-root': {
      display: 'flex',
    },
    '& .MuiTableCell-head': {
      py: {
        xs: 1.5,
        md: 2,
      },
      typography: {
        xs: 'h6',
        md: 'h5',
      },
      width: '50%',
    },
  },
};

type MarketTableProps = {
  data: Market;
  handleCellClick: (market: Market, outcome: Outcome) => void;
  teams: PublishedEventParticipants;
};
const MarketsTableTeamGrouped = ({ data, handleCellClick, teams }: MarketTableProps) => {
  const { outcomes, marketType, isActive } = data;
  const { outcomesByParticipant, outcomesLength } = getOutcomesByParticipantsWithLength(teams, outcomes);
  const columnDisplay = getColumnStyle(data.outcomes);
  return (
    <TableContainer component={Paper} sx={styles.tableContainer}>
      <MuiTable aria-label='events table' sx={styles.table}>
        <TableHead sx={styles.head}>
          <TableRow sx={styles.tableRow}>
            {Object.keys(outcomesByParticipant).map((teamName) => (
              <TableCell align='center' key={teamName} sx={styles.tableCell}>
                {teamName}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.from({ length: outcomesLength }).map((_, rowIndex) => (
            <TableRow sx={styles.tableRow} key={rowIndex}>
              {Object.keys(outcomesByParticipant).map((teamName) => {
                const outcomesForTeam = outcomesByParticipant[teamName] || [];
                const outcome = outcomesForTeam[rowIndex];

                return (
                  <OutcomeTableCell
                    key={outcome?.id}
                    sportId={marketType?.sportId}
                    outcome={outcome}
                    onClick={() => handleCellClick(data, outcome)}
                    disabled={!isActive || !outcome?.isActive}
                    isMarketSingleDisplayType
                    marketTypeName={marketType?.name}
                    columnStyle={columnDisplay}
                  />
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
};

export default MarketsTableTeamGrouped;
