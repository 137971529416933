import { Fab, FabProps } from '@mui/material';
import { ArrowUpward } from '@mui/icons-material';
import { useCallback, RefObject, useState, useEffect } from 'react';
import { useBetslip } from '../../contexts/BetslipContext';
import { VirtuosoHandle } from 'react-virtuoso';

const SCROLL_THRESHOLD = 600;

const makeStyles = (isScrolled: boolean, nudgeForBetslip: boolean) => ({
  button: {
    position: 'absolute',
    bottom: { xs: nudgeForBetslip ? 54 : 16, md: 32 },
    right: { xs: 16, md: 48 },
    width: { xs: 36, md: 40 },
    height: { xs: 36, md: 40 },
    zIndex: 10,
    backgroundColor: 'primary.main',
    color: 'neutral.25',
    boxShadow: 2,
    '&:hover': {
      backgroundColor: 'primary.dark',
    },
    opacity: isScrolled ? 1 : 0,
    visibility: isScrolled ? 'visible' : 'hidden',
    transition: isScrolled
      ? 'opacity 0.3s ease-in, visibility 0s linear 0s'
      : 'opacity 0.3s ease-out, visibility 0s linear 0.3s',
  },
});

type Props = FabProps & {
  virtuosoRef?: RefObject<VirtuosoHandle>;
  isScrolled?: boolean;
};

const BackToTopButton = ({ isScrolled: isScrolledProp = false, virtuosoRef, ...props }: Props) => {
  const [showForMainScroll, setShowForMainScroll] = useState(false);

  const { uniqueEventCount } = useBetslip();
  const mainElement = document.querySelector('main');

  useEffect(() => {
    if (!virtuosoRef) {
      if (!mainElement) return;

      const handleScroll = () => {
        setShowForMainScroll(mainElement?.scrollTop > SCROLL_THRESHOLD);
      };

      handleScroll();

      mainElement.addEventListener('scroll', handleScroll);

      return () => {
        mainElement.removeEventListener('scroll', handleScroll);
      };
    } else {
      setShowForMainScroll(false);
    }
  }, [mainElement, virtuosoRef]);

  const scrollToTop = useCallback(() => {
    const scrollableElement = virtuosoRef?.current || mainElement;

    if (scrollableElement) {
      scrollableElement.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [mainElement, virtuosoRef]);

  const shouldShowButton = !!virtuosoRef ? isScrolledProp : showForMainScroll;
  const nudgeForBetslip = !!uniqueEventCount && !virtuosoRef;

  const styles = makeStyles(shouldShowButton, nudgeForBetslip);

  return (
    <Fab onClick={scrollToTop} sx={styles.button} {...props}>
      <ArrowUpward fontSize='small' />
    </Fab>
  );
};

export default BackToTopButton;
