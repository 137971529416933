export const SIDEBAR_OPTION = {
  SPORTS: 'sports',
  IN_PLAY: 'inPlay',
  IN_PLAY_MATCH: 'inPlayMatch',
  ACCOUNT: 'account',
} as const;

export type SidebarType = (typeof SIDEBAR_OPTION)[keyof typeof SIDEBAR_OPTION];

export const SIDEBAR_CONFIGS: { path: string; sidebarType: SidebarType; hideOnDesktop?: boolean }[] = [
  { path: '/my-account', sidebarType: SIDEBAR_OPTION.ACCOUNT },
  { path: '/in-play/match-view', sidebarType: SIDEBAR_OPTION.IN_PLAY_MATCH },
  { path: '/in-play', sidebarType: SIDEBAR_OPTION.IN_PLAY, hideOnDesktop: true },
  { path: '/sports', sidebarType: SIDEBAR_OPTION.SPORTS },
  { path: '/my-bets', sidebarType: SIDEBAR_OPTION.SPORTS, hideOnDesktop: true },
];

export const HEADER_MENU_ALLOWED_ROUTES = SIDEBAR_CONFIGS.map((config) => config.path);

export const getSidebarType = (pathname: string, isMobile: boolean) => {
  const currentSidebar = SIDEBAR_CONFIGS.find((config) => pathname.startsWith(config.path));

  if (currentSidebar?.hideOnDesktop && !isMobile) return null;

  return currentSidebar?.sidebarType || null;
};

export const drawerWidth = 268;
export const drawerWidthXL = 320;
export const accountDrawerWidth = 268;
export const inPlayDrawerWidth = 320;
export const inPlayDrawerWidthXL = 320;

export const DRAWER_CONFIG = {
  [SIDEBAR_OPTION.SPORTS]: {
    width: {
      xs: drawerWidth,
      xl: drawerWidthXL,
    },
    backgroundColor: 'neutral.800',
    borderColor: 'neutral.600',
  },
  [SIDEBAR_OPTION.ACCOUNT]: {
    width: accountDrawerWidth,
    backgroundColor: 'primary.main',
    borderColor: 'primary.500',
  },
  [SIDEBAR_OPTION.IN_PLAY]: {
    width: {
      xs: inPlayDrawerWidth,
      xl: inPlayDrawerWidthXL,
    },
    backgroundColor: 'neutral.800',
    borderColor: 'neutral.600',
  },
  [SIDEBAR_OPTION.IN_PLAY_MATCH]: {
    width: {
      xs: inPlayDrawerWidth,
      xl: inPlayDrawerWidthXL,
    },
    backgroundColor: 'neutral.800',
    borderColor: 'neutral.600',
  },
};
