import { useMemo } from 'react';
import StyledHeaderTabs from '../atoms/StyledHeaderTabs';
import { useSearchContext } from '../../contexts/SearchContext';
import useTabs from '../../hooks/useTabs';
import SearchTabPanel from '../atoms/SearchTabPanel';
import { Box } from '@mui/material';
import { StyleObj } from '../../@types';
import useLocalization from '../../hooks/useLocalization';

const styles: StyleObj = {
  wrapper: {
    backgroundColor: 'background.default',
  },
  container: {
    flexDirection: 'column',
    width: {
      xs: '100%',
      md: '80%',
    },
    my: 1.5,
    mx: 'auto',
    backgroundColor: 'background.default',
    px: {
      xs: 1.5,
      md: 0,
    },
  },
};

const SearchResults = () => {
  const { searchResults, searchValue } = useSearchContext();
  const { getLocalizedConfig } = useLocalization();

  const tabsConfig = useMemo(
    () =>
      searchResults?.map((item) => ({
        label: item.sport.name,
        value: item.sport.id,
      })),
    [searchResults]
  );

  const localizedTabs = useMemo(
    () => getLocalizedConfig(tabsConfig ?? [], { ns: 'sports' }),
    [getLocalizedConfig, tabsConfig]
  );

  const tabProps = useTabs<string>({
    tabConfig: localizedTabs,
  });

  const selectedSport = useMemo(
    () => searchResults?.find((result) => result.sport.id === tabProps.value),
    [searchResults, tabProps.value]
  );

  if (searchValue.trim().length <= 2) return null;

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.container}>
        {!!tabsConfig?.length && <StyledHeaderTabs tabConfig={localizedTabs} {...tabProps} />}
        <SearchTabPanel value={tabProps.value} events={selectedSport?.events} />
      </Box>
    </Box>
  );
};

export default SearchResults;
