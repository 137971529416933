import StyledSkeleton from '../../atoms/StyledSkeleton';
import { Stack } from '@mui/material';
import SkeletonContainer from '../../atoms/SkeletonContainer';



const FeaturedTableSkeleton = ({ rows = 10 }: { rows?: number }) => {
  return (
    <SkeletonContainer>
      <Stack px={1} sx={{ gap: { xs: 1, md: 1.5 } }}>
        <StyledSkeleton sx={{ height: 24 }} />
        {Array.from({ length: rows }).map((_, index) => (
          <Stack key={index} direction='row' sx={{ height: { xs: 60, md: 80 }, gap: { xs: 1, md: 1.5 } }}>
            <Stack sx={{ width: { xs: '50%', lg: '42.5%' } }}>
              <StyledSkeleton variant='text' sx={{ width: { xs: 140, lg: 320 }, height: { xs: 16, lg: 20 } }} />
              <StyledSkeleton variant='text' sx={{ width: { xs: 100, lg: 200 }, height: { xs: 16, lg: 20 } }} />
            </Stack>
            <StyledSkeleton height='100%' sx={{ width: { xs: '50%', lg: '57.5%' } }} />
          </Stack>
        ))}
      </Stack>
    </SkeletonContainer>
  );
};

export default FeaturedTableSkeleton;
