import { Stack, SxProps, Theme, Typography } from '@mui/material';
import HighlightedParticipantIndicator from '../../atoms/HighlightedParticipantIndicator';
import InPlayScoreChangeIndicator from './InPlayScoreChangeIndicator';
import useScoreChange from '../../../hooks/useScoreChange';
import { PublishedEventListItem, StyleObj } from '../../../@types';
import { mergeSx } from '../../../utils/styles';

const styles: StyleObj = {
  participant: {
    typography: {
      xs: 'body3',
      md: 'body2',
    },
    fontWeight: {
      xs: 600,
      md: 600,
    },
    letterSpacing: { xs: 0, md: 0.5 },
  },
};

const ParticipantInfo = ({
  event,
  side,
  sx,
}: {
  event: PublishedEventListItem;
  side: 'home' | 'away';
  sx?: SxProps<Theme>;
}) => {
  const { scoreChanged } = useScoreChange(event, side);

  const participant = event.participants[side];
  const isHighlighted = participant.isHighlighted;

  return (
    <Stack direction='row' alignItems='center' sx={{ width: '100%' }}>
      {isHighlighted != null && <HighlightedParticipantIndicator isHighlighted={isHighlighted} />}
      <Typography noWrap sx={mergeSx(styles.participant, sx)}>
        {participant?.name || participant?.shortName}
      </Typography>
      <InPlayScoreChangeIndicator isVisible={scoreChanged} />
    </Stack>
  );
};

export default ParticipantInfo;
