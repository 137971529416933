import { Skeleton, SkeletonProps } from '@mui/material';
import { StyleObj } from '../../@types';
import { mergeSx } from '../../utils/styles';

const styles: StyleObj = {
  skeleton: {
    backgroundColor: 'neutral.600',
    borderRadius: 2,
  },
};

const StyledSkeleton = (props: SkeletonProps) => (
  <Skeleton variant='rectangular' {...props} sx={mergeSx(styles.skeleton, props.sx)} />
);

export default StyledSkeleton;
