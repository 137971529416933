export const playerPatterns = [
  {
    pattern: /^(.*?)\bDouble Double\b/i,
    key: 'doubleDouble',
  },
  {
    pattern: /^(.*?)\bTriple Double\b/i,
    key: 'tripleDouble',
  },
];

export const teamPatterns = [
  {
    pattern: /^(.*?)\bTotal Score \(More Than\)\b/gi,
    key: 'teamTotalScoreMoreThan',
  },
  {
    pattern: /^(.*?)\bTotal Score \(Pairs\)\b/gi,
    key: 'teamTotalScorePairs',
  },
  {
    pattern: /^(.+?) Number of Corners \(Pairs\), Ordinary Time$/i,
    key: 'teamNumberOfCornersPairsOrdinaryTime',
  },
  {
    pattern: /^(.+?) Number of Corners \(Singles\), Ordinary Time$/i,
    key: 'teamNumberOfCornersSinglesOrdinaryTime',
  },
  {
    pattern: /^(.+?) To Score, Ordinary Time$/i,
    key: 'teamToScoreOrdinaryTime',
  },
  {
    pattern: /^(.+?) To Score, 1st Half \(Ordinary Time\)$/i,
    key: 'teamToScore1stHalfOrdinaryTime',
  },
  {
    pattern: /^(.+?) To Score, 2nd Half \(Ordinary Time\)$/i,
    key: 'teamToScore2ndHalfOrdinaryTime',
  },
  {
    pattern: /^(.+?) Half With Most Goals$/i,
    key: 'teamHalfWithMostGoals',
  },
  {
    pattern: /^(.+?) Number of Corners \(More Than\), Ordinary Time$/i,
    key: 'teamNumberOfCornersMoreThanOrdinaryTime',
  },
  {
    pattern: /^(.+?) To Win To Nil, 1st Half \(Ordinary Time\)$/i,
    key: 'teamToWinToNil1stHalfOrdinaryTime',
  },
  {
    pattern: /^(.+?) To Win To Nil, 1st Half \(Overtime\)$/i,
    key: 'teamToWinToNil1stHalfOvertime',
  },
  {
    pattern: /^(.+?) To Win To Nil, 2nd Half \(Ordinary Time\)$/i,
    key: 'teamToWinToNil2ndHalfOrdinaryTime',
  },
  {
    pattern: /^(.+?) To Win To Nil, 2nd Half \(Overtime\)$/i,
    key: 'teamToWinToNil2ndHalfOvertime',
  },
  {
    pattern: /^(.+?) To Win To Nil, Ordinary Time$/i,
    key: 'teamToWinToNilOrdinaryTime',
  },
  {
    pattern: /^(.+?) To Win To Nil, Overtime Excluding Penalty Round$/i,
    key: 'teamToWinToNilOvertimeExcludingPenaltyRound',
  },
  {
    pattern: /^(.*?) Goal (\d+|X) Before Minute (\d{2}:\d{2}), Ordinary Time$/i,
    key: 'teamGoalXBeforeMinuteYOrdinaryTime',
  },
  {
    pattern: /^(.*?) Total Score \(Pairs\), Whole Match$/i,
    key: 'teamTotalScorePairsWholeMatch',
  },
  {
    pattern: /^(.*?) Total Score \(More Than\), Whole Match$/i,
    key: 'teamTotalScoreMoreThanWholeMatch',
  },
  {
    pattern: /^(.+?) Total Score \(Pairs\), Ordinary Time$/i,
    key: 'teamTotalScorePairsOrdinaryTime',
  },
  {
    pattern: /^(.+?) Total Score \(More Than\), Ordinary Time$/i,
    key: 'teamTotalScoreMoreThanOrdinaryTime',
  },
  {
    pattern: /^(.+?) Total Score \(Singles\), Whole Match$/i,
    key: 'teamTotalScoreExactWholeMatch',
  },
  {
    pattern: /^(.+?) Total Score \(Singles\), Ordinary Time$/i,
    key: 'teamTotalScoreSinglesOrdinaryTime',
  },
];

export const goalXPatterns = [
  {
    pattern: /Team To Score Goal (\d+|X) With Money Back On No Goal, 1st Half \(Ordinary Time\)/i,
    key: 'teamToScoreGoalXWithMoneyBackOnNoGoal1stHalfOrdinaryTime',
  },
  {
    pattern: /Team To Score Goal (\d+|X) With Money Back On No Goal, 2nd Half \(Ordinary Time\)/i,
    key: 'teamToScoreGoalXWithMoneyBackOnNoGoal2ndHalfOrdinaryTime',
  },
  {
    pattern: /Team To Score Goal (\d+|X) With Money Back On No Goal, Ordinary Time/i,
    key: 'teamToScoreGoalXWithMoneyBackOnNoGoalOrdinaryTime',
  },

  {
    pattern: /Team To Score Goal (\d+|X) With Money Back On No Goal, Overtime/i,
    key: 'teamToScoreGoalXWithMoneyBackOnNoGoalOvertime',
  },

  {
    pattern: /Team To Score Goal (\d+|X) With Money Back On No Goal, Overtime Excluding Penalty Round/i,
    key: 'teamToScoreGoalXWithMoneyBackOnNoGoalOvertimeExcludingPenaltyRound',
  },

  {
    pattern: /Team To Score Goal (\d+|X), 1st Half \(Ordinary Time\)/i,
    key: 'teamToScoreGoalX1stHalfOrdinaryTime',
  },
  {
    pattern: /Team To Score Goal (\d+|X), 1st Half \(Overtime\)/i,
    key: 'teamToScoreGoalX1stHalfOvertime',
  },
  {
    pattern: /Team To Score Goal (\d+|X), 2nd Half \(Ordinary Time\)/i,
    key: 'teamToScoreGoalX2ndHalfOrdinaryTime',
  },
  {
    pattern: /Team To Score Goal (\d+|X), 2nd Half \(Overtime\)/i,
    key: 'teamToScoreGoalX2ndHalfOvertime',
  },

  {
    pattern: /Team To Score Goal (\d+|X), Ordinary Time/i,
    key: 'teamToScoreGoalXOrdinaryTime',
  },
  {
    pattern: /Team To Score Goal (\d+|X), Overtime/i,
    key: 'teamToScoreGoalXOvertime',
  },
  {
    pattern: /Team To Score Goal (\d+|X), Overtime Excluding Penalty Round/i,
    key: 'teamToScoreGoalXOvertimeExcludingPenaltyRound',
  },
  {
    pattern: /Team To Score Goal (\d+|X), Whole Match/i,
    key: 'teamToScoreGoalXWholeMatch',
  },
  {
    pattern: /Player To Score Goal (\d+|X), 1st Half \(Ordinary Time\)/i,
    key: 'playerToScoreGoalX1stHalfOrdinaryTime',
  },
  {
    pattern: /Player To Score Goal (\d+|X), 1st Half \(Overtime\)/i,
    key: 'playerToScoreGoalX1stHalfOvertime',
  },
  {
    pattern: /Player To Score Goal (\d+|X), 2nd Half \(Ordinary Time\)/i,
    key: 'playerToScoreGoalX2ndHalfOrdinaryTime',
  },
  {
    pattern: /Player To Score Goal (\d+|X), 2nd Half \(Overtime\)/i,
    key: 'playerToScoreGoalX2ndHalfOvertime',
  },
  {
    pattern: /Player To Score Goal (\d+|X), Ordinary Time/i,
    key: 'playerToScoreGoalXOrdinaryTime',
  },
  {
    pattern: /Player To Score Goal (\d+|X), Overtime Excluding Penalty Round/i,
    key: 'playerToScoreGoalXOvertimeExcludingPenaltyRound',
  },
  {
    pattern: /Player To Score Goal (\d+|X), Whole Match/i,
    key: 'playerToScoreGoalXWholeMatch',
  },
  {
    pattern: /Action To Score Goal (\d+|X), Ordinary Time/i,
    key: 'actionToScoreGoalXOrdinaryTime',
  },
  {
    pattern: /Action To Score Goal (\d+|X), Overtime Excluding Penalty Round/i,
    key: 'actionToScoreGoalXOvertimeExcludingPenaltyRound',
  },
  {
    pattern: /Goal (\d+|X) Before Minute (\d{2}:\d{2}), Ordinary Time/i,
    key: 'goalXBeforeMinuteYOrdinaryTime',
  },
  {
    pattern: /Interval Of Goal (\d+|X), Ordinary Time/i,
    key: 'intervalOfGoalXOrdinaryTime',
  },
  {
    pattern: /Interval Of Team Goal (\d+|X), Ordinary Time/i,
    key: 'intervalOfTeamGoalXOrdinaryTime',
  },
  {
    pattern: /Matchbet & Team To Score Goal (\d+|X), Ordinary Time/i,
    key: 'matchbetAndTeamToScoreGoalXOrdinaryTime',
  },
  {
    pattern: /Matchbet & Time Of Goal (\d+|X), Ordinary Time/i,
    key: 'matchbetAndTimeOfGoalXOrdinaryTime',
  },
  {
    pattern: /Method of Goal (\d+|X), 1st Half \(Ordinary Time\)/i,
    key: 'methodOfGoalX1stHalfOrdinaryTime',
  },
  {
    pattern: /Method of Goal (\d+|X), 1st Half \(Overtime\)/i,
    key: 'methodOfGoalX1stHalfOvertime',
  },
  {
    pattern: /Method of Goal (\d+|X), 2nd Half \(Ordinary Time\)/i,
    key: 'methodOfGoalX2ndHalfOrdinaryTime',
  },
  {
    pattern: /Method of Goal (\d+|X), 2nd Half \(Overtime\)/i,
    key: 'methodOfGoalX2ndHalfOvertime',
  },
  {
    pattern: /Method of Goal (\d+|X), Ordinary Time/i,
    key: 'methodOfGoalXOrdinaryTime',
  },
  {
    pattern: /Method of Goal (\d+|X), Overtime Excluding Penalty Round/i,
    key: 'methodOfGoalXOvertimeExcludingPenaltyRound',
  },
  {
    pattern: /Team Goal (\d+|X) Before Minute (\d{2}:\d{2}), Ordinary Time/i,
    key: 'teamGoalXBeforeMinuteYOrdinaryTime',
  },
];

export const intervalPatterns = [
  {
    pattern: /^Goals Over\/Under (\d+\.\d+) In Interval (\d{2}:\d{2}-\d{2}(?::\d{2})?\+?)$/i,
    key: 'goalsOverUnderInInterval',
  },
  {
    pattern: /^Both Teams To Score In Interval(\s*,\s*)? (\d{2}:\d{2}-\d{2}(?::\d{2})?\+?)$/i,
    key: 'bothTeamsToScoreInInterval',
  },
  {
    pattern: /^Double Chance In Interval(\s*,\s*)? (\d{2}:\d{2}-\d{2}(?::\d{2})?\+?)$/i,
    key: 'doubleChanceInInterval',
  },
  {
    pattern: /^Corner (\d+) In Interval(\s*,\s*)? (\d{2}:\d{2}-\d{2}(?::\d{2})?\+?)$/i,
    key: 'cornerXInInterval',
  },
  {
    pattern: /^Cards Over\/Under (\d+) In Interval(\s*,\s*)? (\d{2}:\d{2}-\d{2}(?::\d{2})?\+?)$/i,
    key: 'cardsOverUnderInInterval',
  },
  {
    pattern: /^Corners Over\/Under (\d+(?:\.\d+)?) in Interval (\d{2}:\d{2}-\d{2}(?::\d{2})?\+?)$/i,
    key: 'cornersOverUnderXInInterval',
  },
  {
    pattern: /^Corners Over\/Under (\d+) In Interval(\s*,\s*)? (\d{2}:\d{2}-\d{2}(?::\d{2})?\+?)$/i,
    key: 'cornersOverUnderInInterval',
  },
  {
    pattern: /^Free Kicks Over\/Under (\d+) In Interval(\s*,\s*)? (\d{2}:\d{2}-\d{2}(?::\d{2})?\+?)$/i,
    key: 'freeKicksOverUnderInInterval',
  },
  {
    pattern: /^Team Corners Over\/Under (\d+) In Interval(\s*,\s*)? (\d{2}:\d{2}-\d{2}(?::\d{2})?\+?)$/i,
    key: 'teamCornersOverUnderInInterval',
  },
  {
    pattern: /^Team Goals Over\/Under (\d+) In Interval(\s*,\s*)? (\d{2}:\d{2}-\d{2}(?::\d{2})?\+?)$/i,
    key: 'teamGoalsOverUnderInInterval',
  },
  {
    pattern: /^Throw-Ins Over\/Under (\d+) In Interval(\s*,\s*)? (\d{2}:\d{2}-\d{2}(?::\d{2})?\+?)$/i,
    key: 'throwInsOverUnderInInterval',
  },
  {
    pattern: /^Goal Kicks Over\/Under (\d+) In Interval(\s*,\s*)? (\d{2}:\d{2}-\d{2}(?::\d{2})?\+?)$/i,
    key: 'goalKicksOverUnderInInterval',
  },
  {
    pattern: /^Goals Over\/Under (\d+) In Interval(\s*,\s*)? (\d{2}:\d{2}-\d{2}(?::\d{2})?\+?)$/i,
    key: 'goalsOverUnderInInterval',
  },
  {
    pattern: /^Which Player Will Score A Goal In Interval\?, (\d{2}:\d{2}-\d{2}(?::\d{2})?\+?)$/i,
    key: 'whichPlayerWillScoreInInterval',
  },
  {
    pattern: /^Interval Of Card (\d+)?, (\d{2}:\d{2}-\d{2}(?::\d{2})?\+?)$/i,
    key: 'intervalOfCard',
  },
  {
    pattern: /^Interval Of Corner (\d+)?, (\d{2}:\d{2}-\d{2}(?::\d{2})?\+?)$/i,
    key: 'intervalOfCorner',
  },
  {
    pattern: /^Interval Of Goal (\d+)?, (\d{2}:\d{2}-\d{2}(?::\d{2})?\+?)$/i,
    key: 'intervalOfGoal',
  },
  {
    pattern: /^Interval Of Team Goal (\d+)?, (\d{2}:\d{2}-\d{2}(?::\d{2})?\+?)$/i,
    key: 'intervalOfTeamGoal',
  },
  {
    pattern: /^Home Draw Away In Interval(\s*,\s*)? (\d{2}:\d{2}-\d{2}(?::\d{2})?\+?)$/i,
    key: 'homeDrawAwayInInterval',
  },
  {
    pattern: /^Most Corners In Interval(\s*,\s*)? (\d{2}:\d{2}-\d{2}(?::\d{2})?\+?)$/i,
    key: 'mostCornersInInterval',
  },
  {
    pattern: /^Most Corners \(2-Way\) In Interval(\s*,\s*)? (\d{2}:\d{2}-\d{2}(?::\d{2})?\+?)$/i,
    key: 'mostCorners2WayInInterval',
  },
  {
    pattern: /^Will A Card Be Shown In Interval (\d{2}:\d{2}-\d{2}(?::\d{2})?\+?)\?$/i,
    key: 'willACardBeShownInInterval',
  },
  {
    pattern: /^Will A Corner Kick Be Awarded In Interval (\d{2}:\d{2}-\d{2}(?::\d{2})?\+?)\?$/i,
    key: 'willACornerKickBeAwardedInInterval',
  },
  {
    pattern: /^Will A Corner Kick Be Awarded To Team In Interval (\d{2}:\d{2}-\d{2}(?::\d{2})?\+?)\?$/i,
    key: 'willACornerKickBeAwardedToTeamInInterval',
  },
  {
    pattern: /^Will A Goal Be Scored By Team In Interval (\d{2}:\d{2}-\d{2}(?::\d{2})?\+?)\?$/i,
    key: 'willAGoalBeScoredByTeamInInterval',
  },
  {
    pattern: /^Will A Goal Be Scored In Interval (\d{2}:\d{2}-\d{2}(?::\d{2})?\+?)\?$/i,
    key: 'willAGoalBeScoredInInterval',
  },
  {
    pattern: /^Will Corner (\d+) Be Awarded In Interval (\d{2}:\d{2}-\d{2}(?::\d{2})?\+?)\?$/i,
    key: 'willCornerXBeAwardedInInterval',
  },
];

export const scorePatterns = [
  {
    pattern: /Action To Score (\d+|X) With Money Back On No Goal, Ordinary Time/i,
    key: 'actionToScoreXWithMoneyBackOnNoGoalOrdinaryTime',
  },
  {
    pattern: /To Score (\d+|X) Or More Goals, Ordinary Time/i,
    key: 'toScoreXOrMoreGoalsOrdinaryTime',
  },
  {
    pattern: /To Score (\d+|X) Or More Goals, Overtime Excluding Penalty Round/i,
    key: 'toScoreXOrMoreGoalsOvertimeExcludingPenaltyRound',
  },
  {
    pattern: /To Score (\d+|X) Or More Goals, Whole Match/i,
    key: 'toScoreXOrMoreGoalsWholeMatch',
  },
  {
    pattern: /Next Field Goal Method 2-Way \(After Score (\d{1,2}-\d{1,2})\), Whole Match/i,
    key: 'nextFieldGoalMethod2WayAfterScoreXWholeMatch',
  },
  {
    pattern: /Next Field Goal Method 4-Way \(After Score (\d{1,2}-\d{1,2})\), Whole Match/i,
    key: 'nextFieldGoalMethod4WayAfterScoreXWholeMatch',
  },
  {
    pattern: /Both Teams to Score or Over (X|\d+) Goals in Match, 1st Half \(Ordinary Time\)/i,
    key: 'bothTeamsToScoreOrOverXGoalsInMatch1stHalfOrdinaryTime',
  },
  {
    pattern: /Both Teams to Score or Over (X|\d+) Goals in Match, 2nd Half \(Ordinary Time\)/i,
    key: 'bothTeamsToScoreOrOverXGoalsInMatch2ndHalfOrdinaryTime',
  },
  {
    pattern: /Both Teams to Score or Over (X|\d+) Goals in Match, Ordinary Time/i,
    key: 'bothTeamsToScoreOrOverXGoalsInMatchOrdinaryTime',
  },
  {
    pattern: /^Asian Handicap With Score \((\d+:\d+)\),\s*1st half \(ordinary time\)$/i,
    key: 'asianHandicapWithScore1stHalfOrdinaryTime',
  },
  {
    pattern: /^Asian Handicap With Score \((\d+:\d+)\),\s*1st half \(overtime\)$/i,
    key: 'asianHandicapWithScore1stHalfOvertime',
  },
  {
    pattern: /^Asian Handicap With Score \((\d+:\d+)\),\s*2nd half \(ordinary time\)$/i,
    key: 'asianHandicapWithScore2ndHalfOrdinaryTime',
  },
  {
    pattern: /^Asian Handicap With Score \((\d+:\d+)\),\s*2nd half \(overtime\)$/i,
    key: 'asianHandicapWithScore2ndHalfOvertime',
  },
  {
    pattern: /^Asian Handicap With Score \((\d+:\d+)\),\s*Ordinary Time$/i,
    key: 'asianHandicapWithScoreOrdinaryTime',
  },
  {
    pattern: /^Asian Handicap With Score \((\d+:\d+)\),\s*overtime excluding penalty round$/i,
    key: 'asianHandicapWithScoreOvertimeExcludingPenalty',
  },
  {
    pattern: /^Asian Handicap With Score \((\d+:\d+)\),\s*penalty round$/i,
    key: 'asianHandicapWithScorePenaltyRound',
  },
];

export const noBetPatterns = [
  {
    pattern: /^(.*?) No Bet, 1st Half \(Ordinary Time\)$/i,
    key: 'teamNoBet1stHalfOrdinaryTime',
  },
  {
    pattern: /^(.*?) No Bet, 2nd Half \(Ordinary Time\)$/i,
    key: 'teamNoBet2ndHalfOrdinaryTime',
  },
  {
    pattern: /^(.*?) No Bet, Ordinary Time$/i,
    key: 'teamNoBetOrdinaryTime',
  },
  {
    pattern: /^(.*?) No Bet, Overtime Excluding Penalty Round$/i,
    key: 'teamNoBetOvertimeExcludingPenaltyRound',
  },
  // {
  //   pattern: /^(.*?) No Bet, 1st Period$/i,
  //   key: 'teamNoBet1stPeriod',
  // },
  // {
  //   pattern: /^(.*?) No Bet, 2nd Period$/i,
  //   key: 'teamNoBet2ndPeriod',
  // },
  // {
  //   pattern: /^(.*?) No Bet, 3rd Period$/i,
  //   key: 'teamNoBet3rdPeriod',
  // },
];

export const gamesPatterns = [
  {
    pattern: /^Games (\d+) And (\d+) Winner, 1st Set$/i,
    key: 'gamesXAndYWinner1stSet',
  },
  {
    pattern: /^Games (\d+) And (\d+) Winner, 2nd Set$/i,
    key: 'gamesXAndYWinner2ndSet',
  },
  {
    pattern: /^Games (\d+) And (\d+) Winner, 3rd Set$/i,
    key: 'gamesXAndYWinner3rdSet',
  },
  {
    pattern: /^Games (\d+) And (\d+) Winner, 4th Set$/i,
    key: 'gamesXAndYWinner4thSet',
  },
  {
    pattern: /^Games (\d+) And (\d+) Winner, 5th Set$/i,
    key: 'gamesXAndYWinner5thSet',
  },
];

export const racePatterns = [
  {
    pattern: /^Race To (\d+) Games, 1st Set$/i,
    key: 'raceToXGames1stSet',
  },
  {
    pattern: /^Race To (\d+) Games, 2nd Set$/i,
    key: 'raceToXGames2ndSet',
  },
  {
    pattern: /^Race To (\d+) Games, 3rd Set$/i,
    key: 'raceToXGames3rdSet',
  },
  {
    pattern: /^Race To (\d+) Games, 4th Set$/i,
    key: 'raceToXGames4thSet',
  },
  {
    pattern: /^Race To (\d+) Games, 5th Set$/i,
    key: 'raceToXGames5thSet',
  },
  {
    pattern: /^Race To (\d+) Games, Whole Match$/i,
    key: 'raceToXGamesWholeMatch',
  },
  {
    pattern: /^Race To (\d+) Points 2-Way, (\d+)(?:st|nd|rd|th) Game \(1st Set\)$/i,
    key: 'raceToXPoints2WayXGame1stSet',
  },
  {
    pattern: /^Race To (\d+) Points 2-Way, (\d+)(?:st|nd|rd|th) Game \(2nd Set\)$/i,
    key: 'raceToXPoints2WayXGame2ndSet',
  },
  {
    pattern: /^Race To (\d+) Points 2-Way, (\d+)(?:st|nd|rd|th) Game \(3rd Set\)$/i,
    key: 'raceToXPoints2WayXGame3rdSet',
  },
  {
    pattern: /^Race To (\d+) Points 2-Way, (\d+)(?:st|nd|rd|th) Game \(4th Set\)$/i,
    key: 'raceToXPoints2WayXGame4thSet',
  },
  {
    pattern: /^Race To (\d+) Points 2-Way, (\d+)(?:st|nd|rd|th) Game \(5th Set\)$/i,
    key: 'raceToXPoints2WayXGame5thSet',
  },
  {
    pattern: /^Race To (\d+) Points 2-Way, 1st Set$/i,
    key: 'raceToXPoints2Way1stSet',
  },
  {
    pattern: /^Race To (\d+) Points 2-Way, 2nd Set$/i,
    key: 'raceToXPoints2Way2ndSet',
  },
  {
    pattern: /^Race To (\d+) Points 2-Way, 3rd Set$/i,
    key: 'raceToXPoints2Way3rdSet',
  },
  {
    pattern: /^Race To (\d+) Points 2-Way, 4th Set$/i,
    key: 'raceToXPoints2Way4thSet',
  },
  {
    pattern: /^Race To (\d+) Points 2-Way, 5th Set$/i,
    key: 'raceToXPoints2Way5thSet',
  },
  {
    pattern: /^Race To (\d+) Points 2-Way, Tie-Break \(1st Set\)$/i,
    key: 'raceToXPoints2WayTieBreak1stSet',
  },
  {
    pattern: /^Race To (\d+) Points 2-Way, Tie-Break \(2nd Set\)$/i,
    key: 'raceToXPoints2WayTieBreak2ndSet',
  },
  {
    pattern: /^Race To (\d+) Points 2-Way, Tie-Break \(3rd Set\)$/i,
    key: 'raceToXPoints2WayTieBreak3rdSet',
  },
  {
    pattern: /^Race To (\d+) Points 2-Way, Tie-Break \(4th Set\)$/i,
    key: 'raceToXPoints2WayTieBreak4thSet',
  },
  {
    pattern: /^Race To (\d+) Points 2-Way, Tie-Break \(5th Set\)$/i,
    key: 'raceToXPoints2WayTieBreak5thSet',
  },
  {
    pattern: /^Race To (\d+) Points, (\d+)(?:st|nd|rd|th) Game \(1st Set\)$/i,
    key: 'raceToXPoints10thGame1stSet',
  },
  {
    pattern: /^Race To (\d+) Points, (\d+)(?:st|nd|rd|th) Game \(2nd Set\)$/i,
    key: 'raceToXPoints10thGame2ndSet',
  },
  {
    pattern: /^Race To (\d+) Points, (\d+)(?:st|nd|rd|th) Game \(3rd Set\)$/i,
    key: 'raceToXPoints10thGame3rdSet',
  },
  {
    pattern: /^Race To (\d+) Points, (\d+)(?:st|nd|rd|th) Game \(4th Set\)$/i,
    key: 'raceToXPoints10thGame4thSet',
  },
  {
    pattern: /^Race To (\d+) Points, (\d+)(?:st|nd|rd|th) Game \(5th Set\)$/i,
    key: 'raceToXPoints10thGame5thSet',
  },
  {
    pattern: /^Race To (\d+) Points, 1st Set$/i,
    key: 'raceToXPoints1stSet',
  },
  {
    pattern: /^Race To (\d+) Points, 2nd Set$/i,
    key: 'raceToXPoints2ndSet',
  },
  {
    pattern: /^Race To (\d+) Points, 3rd Set$/i,
    key: 'raceToXPoints3rdSet',
  },
  {
    pattern: /^Race To (\d+) Points, 4th Set$/i,
    key: 'raceToXPoints4thSet',
  },
  {
    pattern: /^Race To (\d+) Points, 5th Set$/i,
    key: 'raceToXPoints5thSet',
  },
  {
    pattern: /^Race To (\d+) Points, Tie-Break \(1st Set\)$/i,
    key: 'raceToXPointsTieBreak1stSet',
  },
  {
    pattern: /^Race To (\d+) Points, Tie-Break \(2nd Set\)$/i,
    key: 'raceToXPointsTieBreak2ndSet',
  },
  {
    pattern: /^Race To (\d+) Points, Tie-Break \(3rd Set\)$/i,
    key: 'raceToXPointsTieBreak3rdSet',
  },
  {
    pattern: /^Race To (\d+) Points, Tie-Break \(4th Set\)$/i,
    key: 'raceToXPointsTieBreak4thSet',
  },
  {
    pattern: /^Race To (\d+) Points, Tie-Break \(5th Set\)$/i,
    key: 'raceToXPointsTieBreak5thSet',
  },
  {
    pattern: /^Race to (\d+) Corners 2-Way, 1st Half \(Ordinary Time\)$/i,
    key: 'raceToXCorners2Way1stHalfOrdinaryTime',
  },
  {
    pattern: /^Race to (\d+) Corners 2-Way, 1st Half \(Overtime\)$/i,
    key: 'raceToXCorners2Way1stHalfOvertime',
  },
  {
    pattern: /^Race to (\d+) Corners 2-Way, 2nd Half \(Ordinary Time\)$/i,
    key: 'raceToXCorners2Way2ndHalfOrdinaryTime',
  },
  {
    pattern: /^Race to (\d+) Corners 2-Way, 2nd Half \(Overtime\)$/i,
    key: 'raceToXCorners2Way2ndHalfOvertime',
  },
  {
    pattern: /^Race to (\d+) Corners 2-Way, Ordinary Time$/i,
    key: 'raceToXCorners2WayOrdinaryTime',
  },
  {
    pattern: /^Race to (\d+) Corners 2-Way, Overtime Excluding Penalty Round$/i,
    key: 'raceToXCorners2WayOvertimeExcludingPenaltyRound',
  },
  {
    pattern: /^Race to (\d+) Corners, 1st Half \(Ordinary Time\)$/i,
    key: 'raceToXCorners1stHalfOrdinaryTime',
  },
  {
    pattern: /^Race to (\d+) Corners, 1st Half \(Overtime\)$/i,
    key: 'raceToXCorners1stHalfOvertime',
  },
  {
    pattern: /^Race to (\d+) Corners, 2nd Half \(Ordinary Time\)$/i,
    key: 'raceToXCorners2ndHalfOrdinaryTime',
  },
  {
    pattern: /^Race to (\d+) Corners, 2nd Half \(Overtime\)$/i,
    key: 'raceToXCorners2ndHalfOvertime',
  },
  {
    pattern: /^Race to (\d+) Corners, Ordinary Time$/i,
    key: 'raceToXCornersOrdinaryTime',
  },
  {
    pattern: /^Race to (\d+) Corners, Overtime Excluding Penalty Round$/i,
    key: 'raceToXCornersOvertimeExcludingPenaltyRound',
  },
  {
    pattern: /^Race to (\d+) Goals 2-Way, 1st Half \(Ordinary Time\)$/i,
    key: 'raceToXGoals2Way1stHalfOrdinaryTime',
  },
  {
    pattern: /^Race to (\d+) Goals 2-Way, 1st Half \(Overtime\)$/i,
    key: 'raceToXGoals2Way1stHalfOvertime',
  },
  {
    pattern: /^Race to (\d+) Goals 2-Way, 2nd Half \(Ordinary Time\)$/i,
    key: 'raceToXGoals2Way2ndHalfOrdinaryTime',
  },
  {
    pattern: /^Race to (\d+) Goals 2-Way, 2nd Half \(Overtime\)$/i,
    key: 'raceToXGoals2Way2ndHalfOvertime',
  },
  {
    pattern: /^Race to (\d+) Goals 2-Way, Ordinary Time$/i,
    key: 'raceToXGoals2WayOrdinaryTime',
  },
  {
    pattern: /^Race to (\d+) Goals 2-Way, Overtime$/i,
    key: 'raceToXGoals2WayOvertime',
  },
  {
    pattern: /^Race to (\d+) Goals 2-Way, Overtime Excluding Penalty Round$/i,
    key: 'raceToXGoals2WayOvertimeExcludingPenaltyRound',
  },
  {
    pattern: /^Race to (\d+) Goals, 1st Half \(Ordinary Time\)$/i,
    key: 'raceToXGoals1stHalfOrdinaryTime',
  },
  {
    pattern: /^Race to (\d+) Goals, 1st Half \(Overtime\)$/i,
    key: 'raceToXGoals1stHalfOvertime',
  },
  {
    pattern: /^Race to (\d+) Goals, 2nd Half \(Ordinary Time\)$/i,
    key: 'raceToXGoals2ndHalfOrdinaryTime',
  },
  {
    pattern: /^Race to (\d+) Goals, 2nd Half \(Overtime\)$/i,
    key: 'raceToXGoals2ndHalfOvertime',
  },
  {
    pattern: /^Race to (\d+) Goals, Ordinary Time$/i,
    key: 'raceToXGoalsOrdinaryTime',
  },
  {
    pattern: /^Race to (\d+) Goals, Overtime$/i,
    key: 'raceToXGoalsOvertime',
  },
  {
    pattern: /^Race to (\d+) Goals, Overtime Excluding Penalty Round$/i,
    key: 'raceToXGoalsOvertimeExcludingPenaltyRound',
  },
  {
    pattern: /^Race to (\d+) Goals, Penalty Round$/i,
    key: 'raceToXGoalsPenaltyRound',
  },
  {
    pattern: /^Race to (\d+) Goals, Whole Match$/i,
    key: 'raceToXGoalsWholeMatch',
  },
];

export const firstPatterns = [
  {
    pattern: /^First 2 Points Of Game (\d+) Of Set (\d+), (\d+)(?:st|nd|rd|th) Game \(1st Set\)$/i,
    key: 'first2PointsOfGameXOfSetY1stSet',
  },
  {
    pattern: /^First 2 Points Of Game (\d+) Of Set (\d+), (\d+)(?:st|nd|rd|th) Game \(2nd Set\)$/i,
    key: 'first2PointsOfGameXOfSetY2ndSet',
  },
  {
    pattern: /^First 2 Points Of Game (\d+) Of Set (\d+), (\d+)(?:st|nd|rd|th) Game \(3rd Set\)$/i,
    key: 'first2PointsOfGameXOfSetY3rdSet',
  },
  {
    pattern: /^First 2 Points Of Game (\d+) Of Set (\d+), (\d+)(?:st|nd|rd|th) Game \(4th Set\)$/i,
    key: 'first2PointsOfGameXOfSetY4thSet',
  },
  {
    pattern: /^First 2 Points Of Game (\d+) Of Set (\d+), (\d+)(?:st|nd|rd|th) Game \(5th Set\)$/i,
    key: 'first2PointsOfGameXOfSetY5thSet',
  },
  {
    pattern: /^(.+) First Service Game$/i,
    key: 'firstServiceGame',
  },
  {
    pattern: /^first team goalscorer, 1st half \(ordinary time\)$/,
    key: 'firstTeamGoalscorer1stHalfOrdinaryTime',
  },
  {
    pattern: /^first team goalscorer, 1st half \(overtime\)$/,
    key: 'firstTeamGoalscorer1stHalfOvertime',
  },
  {
    pattern: /^first team goalscorer, 2nd half \(ordinary time\)$/,
    key: 'firstTeamGoalscorer2ndHalfOrdinaryTime',
  },
  {
    pattern: /^first team goalscorer, 2nd half \(overtime\)$/,
    key: 'firstTeamGoalscorer2ndHalfOvertime',
  },
  {
    pattern: /^first team goalscorer, ordinary time$/,
    key: 'firstTeamGoalscorerOrdinaryTime',
  },
  {
    pattern: /^first team goalscorer, overtime excluding penalty round$/,
    key: 'firstTeamGoalscorerOvertimeExcludingPenalty',
  },
  {
    pattern: /^first team goalscorer, whole match$/,
    key: 'firstTeamGoalscorerWholeMatch',
  },
];

export const cornerPatterns = [
  {
    pattern: /^Corner (\d+) 2-Way, 1st Half \(Ordinary Time\)$/i,
    key: 'cornerX2Way1stHalfOrdinaryTime',
  },
  {
    pattern: /^Corner (\d+) 2-Way, 1st Half \(Overtime\)$/i,
    key: 'cornerX2Way1stHalfOvertime',
  },
  {
    pattern: /^Corner (\d+) 2-Way, 2nd Half \(Ordinary Time\)$/i,
    key: 'cornerX2Way2ndHalfOrdinaryTime',
  },
  {
    pattern: /^Corner (\d+) 2-Way, 2nd Half \(Overtime\)$/i,
    key: 'cornerX2Way2ndHalfOvertime',
  },
  {
    pattern: /^Corner (\d+) 2-Way, Ordinary Time$/i,
    key: 'cornerX2WayOrdinaryTime',
  },
  {
    pattern: /^Corner (\d+) 2-Way, Overtime Excluding Penalty Round$/i,
    key: 'cornerX2WayOvertimeExcludingPenaltyRound',
  },
  {
    pattern: /^Corner (\d+) in Interval, Ordinary Time$/i,
    key: 'cornerXInIntervalOrdinaryTime',
  },
  {
    pattern: /^Corner (\d+), 1st Half \(Ordinary Time\)$/i,
    key: 'cornerX1stHalfOrdinaryTime',
  },
  {
    pattern: /^Corner (\d+), 1st Half \(Overtime\)$/i,
    key: 'cornerX1stHalfOvertime',
  },
  {
    pattern: /^Corner (\d+), 2nd Half \(Ordinary Time\)$/i,
    key: 'cornerX2ndHalfOrdinaryTime',
  },
  {
    pattern: /^Corner (\d+), 2nd Half \(Overtime\)$/i,
    key: 'cornerX2ndHalfOvertime',
  },
  {
    pattern: /^Corner (\d+), Ordinary Time$/i,
    key: 'cornerXOrdinaryTime',
  },
  {
    pattern: /^Corner (\d+), Overtime Excluding Penalty Round$/i,
    key: 'cornerXOvertimeExcludingPenaltyRound',
  },
  {
    pattern: /^Corners Over\/Under (\d+), 1st Half \(Ordinary Time\)$/i,
    key: 'cornersOverUnderX1stHalfOrdinaryTime',
  },
  {
    pattern: /^Corners Over\/Under (\d+), 1st Half \(Overtime\)$/i,
    key: 'cornersOverUnderX1stHalfOvertime',
  },
  {
    pattern: /^Corners Over\/Under (\d+), 2nd Half \(Ordinary Time\)$/i,
    key: 'cornersOverUnderX2ndHalfOrdinaryTime',
  },
  {
    pattern: /^Corners Over\/Under (\d+), 2nd Half \(Overtime\)$/i,
    key: 'cornersOverUnderX2ndHalfOvertime',
  },
  {
    pattern: /^Corners Over\/Under (\d+), Ordinary Time$/i,
    key: 'cornersOverUnderXOrdinaryTime',
  },
  {
    pattern: /^Corners Over\/Under (\d+), Overtime Excluding Penalty Round$/i,
    key: 'cornersOverUnderXOvertimeExcludingPenaltyRound',
  },
];
