import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { memo, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FavoriteTournament, PaginatedData, PublishedEventListItem, StyleObj, TopMarketType } from '../../@types';
import { QUERY_KEYS } from '../../constants';
import { MessageBetType } from '../../helpers/betslipMessageParser';
import eventListItemUpdater from '../../helpers/eventListItemUpdater';
import useWebsocket, { MessageType } from '../../hooks/useWebsocket';
import { isCheckboxClicked } from '../../utils';
import { getData } from '../../utils/api';
import FavoritesCheckbox from '../atoms/FavoritesCheckbox';
import useMobileOnHold from '../../hooks/useMobileOnHold';
import FavoriteEvent from './FavoriteEvent';

const EXPAND_ICON_WIDTH = 24;

const styles = {
  accordion: {
    backgroundColor: 'neutral.800',
    '& .MuiAccordionDetails-root': {
      cursor: 'pointer',
    },
    '&::before': {
      backgroundColor: 'neutral.600',
    },
    transition: 'all 300ms ease',
  },
  accordionSummary: {
    pl: 3,
    pr: 1.5,
    '& .MuiAccordionSummary-content': {
      alignItems: 'center',
      maxWidth: `calc(100% - ${EXPAND_ICON_WIDTH}px)`,
    },
  },
  accordionSummaryIcon: { color: 'neutral.300' },
  loader: { color: 'neutral.400', mx: 0.5 },
  participantContainer: { alignItems: 'start', flex: '1 1 auto', minWidth: 0 },
  scoreContainer: { flexDirection: 'row', alignItems: 'end', flex: '0 0 auto' },
} satisfies StyleObj;

type Props = {
  tournament: FavoriteTournament;
  selectedEventTournamentId?: string;
  closeDrawer: () => void;
  sportId: string;
};

const InPlaySidebarTournamentAccordion = ({ tournament, selectedEventTournamentId, closeDrawer, sportId }: Props) => {
  const [expanded, setExpanded] = useState(false);
  const [events, setEvents] = useState<PublishedEventListItem[]>([]);
  const { isChecked, handleFavorite } = useMobileOnHold(!!tournament.favourite, 'tournaments', tournament.id);

  const { data: publishedEventsData, isInitialLoading } = useQuery({
    queryKey: [QUERY_KEYS.publishedEvents, tournament.id],
    queryFn: (): Promise<PaginatedData<PublishedEventListItem> & { topMarketType: TopMarketType }> =>
      getData('events/published', {
        page: 1,
        limit: 50,
        sportId,
        tournamentIds: [tournament.id],
        isActive: true,
        isLive: true,
        count: false,
      }),
    enabled: !!tournament?.id && !!sportId && expanded,
  });

  const updaterCallback = useCallback((data: Record<string, MessageType<MessageBetType>>[]) => {
    setEvents((prevState) => {
      const eventsMap = new Map(prevState.map((event) => [event.id, event]));
      const updatedEvents = data.reduce((acc, message) => {
        const updatedEventId = Object.keys(message)[0];
        const eventToUpdate = eventsMap.get(updatedEventId);

        if (eventToUpdate) {
          const updatedEvent = eventListItemUpdater(eventToUpdate, message[updatedEventId]);
          acc.set(updatedEventId, updatedEvent);
        }

        return acc;
      }, new Map<string, PublishedEventListItem>());
      return prevState.map((event) => updatedEvents.get(event.id) ?? event);
    });
  }, []);

  const { joinRoom, leaveRoom } = useWebsocket<MessageType<MessageBetType>>({
    callback: updaterCallback,
  });

  // On mount, open tournament accordion containing the event from the event page
  useEffect(() => {
    if (tournament?.id === selectedEventTournamentId) {
      setExpanded(true);
    }
  }, [selectedEventTournamentId, tournament.id]);

  useEffect(() => {
    if (publishedEventsData) {
      setEvents(publishedEventsData.items);
      publishedEventsData?.items?.forEach((event) => {
        joinRoom(`${event.id}_reduced`);
      });
    }
  }, [joinRoom, publishedEventsData]);

  useEffect(() => {
    if (!expanded) {
      events.forEach((event) => {
        leaveRoom(`${event.id}_reduced`);
      });
    }
  }, [expanded, leaveRoom, events]);

  const navigate = useNavigate();

  const toggleAccordion = (event: React.SyntheticEvent) => {
    if (isCheckboxClicked(event)) return;

    setExpanded((prev) => !prev);
  };

  const handleEventClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, id: string) => {
    if (isCheckboxClicked(event)) return;

    closeDrawer();
    navigate(`/in-play/match-view/${id}`);
  };

  return (
    <Accordion disableGutters elevation={0} expanded={expanded} onChange={toggleAccordion} sx={styles.accordion}>
      <AccordionSummary
        sx={styles.accordionSummary}
        expandIcon={
          isInitialLoading ? (
            <CircularProgress size={16} sx={styles.loader} />
          ) : (
            <ExpandMore sx={styles.accordionSummaryIcon} />
          )
        }
      >
        <Typography variant='h5' fontWeight={700} noWrap>
          {tournament.name}
        </Typography>
        <FavoritesCheckbox isChecked={isChecked} variant='medium' sx={{ py: 0 }} onChange={handleFavorite} />
      </AccordionSummary>
      <AccordionDetails>
        {events?.map((event) => <FavoriteEvent key={event.id} event={event} handleEventClick={handleEventClick} />)}
      </AccordionDetails>
    </Accordion>
  );
};

export default memo(InPlaySidebarTournamentAccordion);
