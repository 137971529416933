import { memo } from 'react';
import useOddsFormat from '../../hooks/useOddsFormat';
import { Typography, TypographyProps } from '@mui/material';

type OddsDisplayProps = TypographyProps & {
  value: string | number;
};

const OddsDisplay = memo(({ value, ...rest }: OddsDisplayProps) => {
  const { formatOddsValue } = useOddsFormat();

  return (
    <Typography component='span' {...rest}>
      {formatOddsValue(value)}
    </Typography>
  );
});

OddsDisplay.displayName = 'OddsDisplay';

export default OddsDisplay;
