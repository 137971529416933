import { Box, Divider, Drawer } from '@mui/material';
import { StyleObj } from '../../@types';
import { DRAWER_CONFIG, SidebarType } from '../../config/sidebar';
import useBreakpoints from '../../hooks/useBreakpoints';
import Logo from '../icons/Logo';
import SidebarTabs from '../atoms/SidebarTabs';

type DrawerLayoutProps = {
  isOpen: boolean;
  toggleVisibility: (isOpen: boolean) => void;
  children: React.ReactNode;
  sidebarType: SidebarType;
};

const makeStyles = (sidebarType: SidebarType): StyleObj => ({
  logoWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    filter: 'grayscale(100%)',
    pt: 1.5,
  },
  box: {
    flexShrink: 0,
    whiteSpace: 'nowrap',
    height: '100vh',
    overflowX: 'auto',
    backgroundColor: DRAWER_CONFIG[sidebarType].backgroundColor,
    pt: 8,
    width: DRAWER_CONFIG[sidebarType].width,
  },
  divider: {
    borderColor: DRAWER_CONFIG[sidebarType].borderColor,
    my: 1,
  },
  drawer: {
    '& .MuiDrawer-paper': {
      backgroundColor: DRAWER_CONFIG[sidebarType].backgroundColor,
      minWidth: DRAWER_CONFIG[sidebarType].width,
      maxWidth: DRAWER_CONFIG[sidebarType].width,
    },
  },
});

const DrawerLayout = ({ isOpen, toggleVisibility, children, sidebarType }: DrawerLayoutProps) => {
  const styles = makeStyles(sidebarType);
  const { isMobile } = useBreakpoints();

  const handleClose = () => toggleVisibility(false);

  return isMobile ? (
    <Drawer sx={styles.drawer} open={isOpen} variant='temporary' onClose={handleClose}>
      <Box sx={styles.logoWrapper}>
        <Logo isClickable={false} />
      </Box>
      <SidebarTabs onDrawerClose={handleClose} />
      <Divider sx={styles.divider} />
      {children}
    </Drawer>
  ) : (
    <Box sx={styles.box}>{children}</Box>
  );
};

export default DrawerLayout;
