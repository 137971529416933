export const outcomeRegexArray = [
  { rule: /\bBoth teams to score\b/gi, key: 'bttsYes' },
  { rule: /\bBoth teams not to score\b/gi, key: 'bttsNo' },
  { rule: /\bTotal Score\b/gi, key: 'totalScore' },
  { rule: /\b1st Half\b/gi, key: '1stHalf' },
  { rule: /\b2nd Half\b/gi, key: '2ndHalf' },
  { rule: /\(Ordinary Time\)/gi, key: 'ordinaryTime' },
  { rule: /\b1st Quarter\b/gi, key: '1stQuarter' },
  { rule: /\b2nd Quarter\b/gi, key: '2ndQuarter' },
  { rule: /\b3rd Quarter\b/gi, key: '3rdQuarter' },
  { rule: /\b4th Quarter\b/gi, key: '4thQuarter' },
  { rule: /\b1st Period\b/gi, key: '1stPeriod' },
  { rule: /\b2nd Period\b/gi, key: '2ndPeriod' },
  { rule: /\b3rd Period\b/gi, key: '3rdPeriod' },
  { rule: /(3\s*point\s*shot|three\s*pointer)/i, key: 'threePointer' },
  { rule: /(2\s*point\s*shot|two\s*pointer)/i, key: 'twoPointer' },
  { rule: /\bFree Throw\b/gi, key: 'freeThrow' },
  { rule: /\bFree Kick\b/gi, key: 'freeKick' },
  { rule: /\bCorner Kick\b/gi, key: 'cornerKick' },
  { rule: /\bGoal Kick\b/gi, key: 'goalKick' },
  { rule: /\bThrow[-\s]In\b/gi, key: 'throwIn' },
  { rule: /\bOwn Goal\b/gi, key: 'ownGoal' },
  { rule: /\bRun Solo\b/gi, key: 'runSolo' },
  { rule: /\bNo Card\b/gi, key: 'noCard' },
  { rule: /\bNo Corner\b/gi, key: 'noCorner' },
  { rule: /\bNo Goal\b/gi, key: 'noGoal' },
  { rule: /\bAny Other\b/gi, key: 'anyOther' },
  { rule: /\bLeg Before Wicket\b/gi, key: 'lbw' },
  { rule: /\bRun Out\b/gi, key: 'runOut' },
  { rule: /\bStumped\b/gi, key: 'stumped' },
  { rule: /\bHeader\b/gi, key: 'header' },
  { rule: /\bPenalty\b/gi, key: 'penalty' },
  { rule: /\bFirst Half\b/gi, key: '1h' },
  { rule: /\bSecond Half\b/gi, key: '2h' },
  { rule: /\bCaught\b/gi, key: 'caught' },
  { rule: /\bBowled\b/gi, key: 'bowled' },
  { rule: /\bServer Loses\b/gi, key: 'serverLoses' },
  { rule: /\bService Held\b/gi, key: 'serviceHeld' },
  { rule: /\bService Broken\b/gi, key: 'serviceBroken' },
  { rule: /\bBlack\b/gi, key: 'black' },
  { rule: /\bBlue\b/gi, key: 'blue' },
  { rule: /\bBrown\b/gi, key: 'brown' },
  { rule: /\bGreen\b/gi, key: 'green' },
  { rule: /\bPink\b/gi, key: 'pink' },
  { rule: /\bYellow\b/gi, key: 'yellow' },
  { rule: /\bTop\b/gi, key: 'top' },
  { rule: /\bBottom\b/gi, key: 'bottom' },
  { rule: /\bInning\b/gi, key: 'inning' },
  { rule: /\bHalf\b/gi, key: 'half' },
  { rule: /\bQuarter\b/gi, key: 'quarter' },
  { rule: /\bGoal\b/gi, key: 'goal' },
  { rule: /\bNeither\b/gi, key: 'neither' },
  { rule: /\bNone\b/gi, key: 'none' },
  { rule: /\bCorner\b/gi, key: 'corner' },
  { rule: /\bTry\b/gi, key: 'try' },
  { rule: /\bExtra\b/gi, key: 'extra' },
  { rule: /\bOdd\b/gi, key: 'odd' },
  { rule: /\bEven\b/gi, key: 'even' },
  { rule: /\bBefore\b/gi, key: 'before' },
  { rule: /\bBy\b/gi, key: 'by' },
  { rule: /\bOver\b/gi, key: 'over' },
  { rule: /\bUnder\b/gi, key: 'under' },
  { rule: /\bOnly\b/gi, key: 'only' },
  { rule: /\bBoth\b/gi, key: 'both' },
  { rule: /\bKick\b/gi, key: 'kick' },
  { rule: /\bHome\b/gi, key: 'home' },
  { rule: /\bDraw\b/gi, key: 'draw' },
  { rule: /\bAway\b/gi, key: 'away' },
  { rule: /\bAnd\b/gi, key: 'and' },
  { rule: /\bOr\b/gi, key: 'or' },
  { rule: /\bMore\b/gi, key: 'more' },
  { rule: /\bLess\b/gi, key: 'less' },
  { rule: /\bTo\b(?!\s*Head)/gi, key: 'to' },
  { rule: /\bYes\b/gi, key: 'yes' },
  { rule: /\bNo\b/gi, key: 'no' },
  { rule: /(\(W\))|(\bW\b)/g, key: 'w' },
  { rule: /\bReverse Forecast\b/gi, key: 'reverseForecast' },
  { rule: /\bStraight Forecast\b/gi, key: 'straightForecast' },
  { rule: /\bMulti Goals\b/gi, key: 'multiGoals' },
  { rule: /\bHead To Head\b/gi, key: 'headToHead' },
  { rule: /\bNumber Of Cards\b/gi, key: 'numberOfCards' },
  { rule: /\bWinner\b/gi, key: 'winner' },
];
