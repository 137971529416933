import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Betslip, AuthorizationResponse, BettingSlipAcceptStatus } from '../@types';
import { isFeedApiError } from '../@types/api';
import { TRIGGER_MAINTENANCE_MODE_EVENT } from '../components/layouts/MarketplaceLayout';
import { QUERY_KEYS } from '../constants';
import { ERROR_CODE } from '../constants/errorCodes';
import { ErrorMessageOddsChangePayload, useBetslip } from '../contexts/BetslipContext';
import { postData } from '../utils/api';
import { useExtendedSnackbar } from '../hooks/useExtendedSnackbar';
import { useInvalidateQuery } from '../hooks/useInvalidateQuery';
import { StakeAmountInput } from '../components/organisms/BetslipDrawer';
import useLocalization from './useLocalization';

const usePlaceBet = ({
  discardBetslip,
  setStakeAmounts,
}: {
  discardBetslip: () => void;
  setStakeAmounts: (stakeAmounts: StakeAmountInput) => void;
}) => {
  const { enqueueSnackbar, enqueueWithDelay, closeSnackbar, showTicketAuthorizationCountMessage } =
    useExtendedSnackbar();
  const { updateSingleBetOdds } = useBetslip();
  const invalidateData = useInvalidateQuery();
  const { t } = useLocalization();

  const placeBetMutation = useMutation({
    mutationFn: (newBetslip: Betslip) => postData('betting-slips', newBetslip),
    onMutate: () => {
      enqueueSnackbar(t('awaitingConfirmation'), {
        variant: 'info',
        persist: true,
        key: 'awaitingConfirmation',
        transitionDuration: 0,
      });
    },
    onSuccess: (data: AuthorizationResponse[]) => {
      closeSnackbar('awaitingConfirmation');
      if (Array.isArray(data)) {
        const MESSAGE_DELAY = 3000;

        const rejectedMessages: string[] = [];
        const statusCounts = data.reduce(
          (counts, { acceptStatus, detailedResults }) => {
            counts[acceptStatus] = (counts[acceptStatus] || 0) + 1;

            if (acceptStatus === 'rejected') {
              const message = Array.from(
                new Set(detailedResults.map((result) => result.clientMessage).filter(Boolean))
              ).join('\n'); // Filter out duplicate messages

              rejectedMessages.push(message || t('bettingSlipRejected'));
            }
            return counts;
          },
          { pending: 0, rejected: 0, accepted: 0 } as Record<Partial<BettingSlipAcceptStatus>, number>
        );

        let cumulativeDelay = 0;

        if (statusCounts.pending > 0) {
          showTicketAuthorizationCountMessage('pending', statusCounts.pending, cumulativeDelay);
          cumulativeDelay += MESSAGE_DELAY;
        }

        rejectedMessages.forEach((message, index) => {
          enqueueWithDelay(
            message,
            { variant: 'warning', style: { whiteSpace: 'pre-line' }, preventDuplicate: false },
            cumulativeDelay + MESSAGE_DELAY * index
          );
        });

        cumulativeDelay += statusCounts.rejected * MESSAGE_DELAY;

        if (statusCounts.accepted > 0) {
          showTicketAuthorizationCountMessage('accepted', statusCounts.accepted, cumulativeDelay);
        }

        if (!statusCounts.pending) {
          discardBetslip();
        }

        invalidateData([
          QUERY_KEYS.myBetsCount,
          QUERY_KEYS.myBets,
          QUERY_KEYS.balance,
          QUERY_KEYS.jackpots,
          QUERY_KEYS.pendingBetslipsCount,
        ]);

        setStakeAmounts({} as StakeAmountInput);
      }
    },

    onError: (error) => {
      closeSnackbar('awaitingConfirmation');

      if (error instanceof AxiosError) {
        const httpCode = error.response?.status;
        const errorMessageObject = error?.response?.data?.errorMessage;

        if (
          isFeedApiError<ErrorMessageOddsChangePayload>(errorMessageObject) &&
          errorMessageObject.code === ERROR_CODE.ODDS_CHANGED
        ) {
          const updatedOutcome = errorMessageObject?.additionalData;

          if (updatedOutcome) {
            updateSingleBetOdds(updatedOutcome);
          }

          return;
        }

        if (httpCode === 503) {
          window.dispatchEvent(new CustomEvent(TRIGGER_MAINTENANCE_MODE_EVENT));
        } else {
          enqueueSnackbar(errorMessageObject?.message || errorMessageObject || 'Internal Server Error', {
            variant: 'error',
          });
        }
      }
    },
  });

  return { placeBetMutation };
};

export default usePlaceBet;
