import CMSSideItem from '../molecules/CMSSideItem';
import { Box, BoxProps, Stack } from '@mui/material';
import { SanitySideItem } from '../../@types';
import { useSanity } from '../../hooks/useSanity';
import StyledSkeleton from '../atoms/StyledSkeleton';

const CMSSideContent = ({ sx }: BoxProps) => {
  const { data: cmsContent, isLoading } = useSanity<SanitySideItem>('PromotionalSideContent');

  return (
    <Box
      sx={{
        height: '100vh',
        position: 'relative',
        display: 'flex',
        overflowY: 'scroll',
        flexDirection: 'column',
        gap: 1,
        ...sx,
      }}
    >
      {isLoading && (
        <Stack p={1} width='100%'>
          {Array.from({ length: 4 }).map((_, index) => (
            <StyledSkeleton key={index} variant='rectangular' width='100%' height={'25vh'} sx={{ mb: 1 }} />
          ))}
        </Stack>
      )}
      {cmsContent?.map((item) => <CMSSideItem key={item.title} item={item} />)}
    </Box>
  );
};

export default CMSSideContent;
