import { Fade, Typography } from '@mui/material';
import useLocalization from '../../../hooks/useLocalization';
import { StyleObj } from '../../../@types';
import { SCORE_CHANGE_INDICATOR_DURATION } from '../../../hooks/useScoreChange';

const makeStyles = (isVisible: boolean): StyleObj => ({
  badge: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'info.main',
    color: 'neutral.800',
    px: {
      xs: 0.5,
      md: 1,
    },
    py: {
      xs: 0.125,
      md: 0.25,
    },
    borderRadius: 0.5,
    boxShadow: 1,
    animation: isVisible ? `pulse 1s ${SCORE_CHANGE_INDICATOR_DURATION / 1000}` : 'none',
    '@keyframes pulse': {
      '0%': { opacity: 1 },
      '50%': { opacity: 0.25 },
      '100%': { opacity: 1 },
    },
    fontWeight: 700,
    fontSize: { xs: 8, md: 10 },
    ml: 1,
  },
});

const InPlayScoreChangeIndicator = ({ isVisible }: { isVisible: boolean }) => {
  const { t } = useLocalization();

  const styles = makeStyles(isVisible);

  return (
    <Fade in={isVisible} unmountOnExit>
      <Typography component='span' sx={styles.badge}>
        {t('scoreChanged')}
      </Typography>
    </Fade>
  );
};

export default InPlayScoreChangeIndicator;
