import { ContentCopy, DoneAll } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { BetslipFrom, BetWithMarketOutcomeEvent, ResponseData, StyleObj } from '../../@types';
import { BETSLIP_FROM, QUERY_KEYS } from '../../constants';
import { useBetslip } from '../../contexts/BetslipContext';
import { useExtendedSnackbar } from '../../hooks/useExtendedSnackbar';
import useLocalization from '../../hooks/useLocalization';
import { getData } from '../../utils/api';

const styles: StyleObj = {
  copyIcon: {
    color: 'neutral.200',
    fontSize: {
      xs: 16,
      md: 20,
    },
    transition: 'all 0.3s ease',
    ':hover': {
      color: 'neutral.50',
      cursor: 'pointer',
    },
  },
};

type Props = {
  betslipId: string;
  from: BetslipFrom;
};

const CopyBetslip = ({ betslipId, from }: Props) => {
  const [isCopySuccess, setIsCopySuccess] = useState(false);
  const { insertCopiedBetslip } = useBetslip();

  const { t } = useLocalization();
  const { enqueueSnackbar } = useExtendedSnackbar();

  const canCopy = from === BETSLIP_FROM.PRE_MATCH;

  const { refetch } = useQuery({
    queryKey: [QUERY_KEYS.copyBetslip, betslipId],
    queryFn: (): Promise<ResponseData<BetWithMarketOutcomeEvent>> =>
      getData('/bets/copy', {
        bettingSlipId: betslipId,
      }),
    enabled: false,
    onSuccess: (data) => {
      if (data.items.length === 0) {
        enqueueSnackbar(t('betslipNothingToCopy'), { variant: 'info' });
        return;
      }

      const payload = data?.items?.map((bet) => ({
        eventId: bet.event.id,
        outcomeId: bet.outcome.id,
        odds: bet.outcome.odds,
        banker: bet.banker,
        eventName: bet.event.name,
        marketId: bet.market.id,
        marketName: bet.market.name,
        marketShortName: bet.market.marketType.shortName,
        marketType: bet.market.marketType,
        marketTypeName: bet.market.marketType.name,
        outcomeName: bet.outcome.name,
        outcomeShortName: bet.outcome.shortName,
        manualControl: bet.event.manualControl || bet.market.manualControl,
        specialValues: bet.specialValues,
      }));

      if (payload) {
        insertCopiedBetslip(payload);
        setIsCopySuccess(true);
        setTimeout(() => setIsCopySuccess(false), 3000);
      }
    },
  });

  const copyBetslip = async (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();
    await refetch();
  };

  if (!canCopy) return null;

  return isCopySuccess ? (
    <DoneAll sx={styles.copyIcon} />
  ) : (
    <Tooltip title={t('copy')}>
      <ContentCopy sx={styles.copyIcon} onClick={copyBetslip} />
    </Tooltip>
  );
};

export default CopyBetslip;
