import { useEffect } from 'react';

export const preventPWA = (restrictedDomains: string[] = []): boolean => {
  const host = window.location.hostname;
  const restrictedDomain = !!restrictedDomains.some((value) => host.includes(value));
  const restrictedBrowser = !!window.AddToHomeScreenInstance?.isDesktopChrome();

  return restrictedDomain || restrictedBrowser;
};

const useManifestLoad = () => {
  useEffect(() => {
    const loadManifest = () => {
      const manifestLink = document.createElement('link');
      manifestLink.rel = 'manifest';
      manifestLink.href = '/manifest.json';
      document.head.appendChild(manifestLink);

      return () => {
        document.head.removeChild(manifestLink);
      };
    };
    if (!preventPWA()) {
      loadManifest();
    }
  }, []);
};

export default useManifestLoad;
