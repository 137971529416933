import { useFeatureValue } from '@growthbook/growthbook-react';
import { BarChart } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { StatscoreConfigData } from '../../@types';
import { useModal } from '../../hooks/useDialog';
import StatScoreModal from './StatScoreModal';

const styles = {
  button: {
    '&.MuiIconButton-root': {
      '&:hover': { backgroundColor: 'transparent' },
      pr: 0,
      py: 0,
    },
  },
  icon: {
    color: 'neutral.100',
    typography: { xs: 'body3', md: 'body1' },
    '&:hover': { color: 'neutral.25' },
  },
};

const UpcomingStatsButton = ({ eventId }: { eventId?: string | null }) => {
  const statscoreConfig = useFeatureValue<StatscoreConfigData | null>('statscore-config', null);
  const groupConfigurationId = statscoreConfig?.prematchConfigurationId;
  const { openModal } = useModal();

  if (!groupConfigurationId || !eventId) return null;

  return (
    <IconButton
      onClick={() => {
        openModal(<StatScoreModal eventId={eventId} configurationId={groupConfigurationId} />);
      }}
      sx={styles.button}
    >
      <BarChart sx={styles.icon} />
    </IconButton>
  );
};

export default UpcomingStatsButton;
