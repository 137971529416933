import { useTheme } from '@mui/material';
import { JACKPOT_COLOR } from '../molecules/JackpotInfo';

const ArrowIcon = () => {
  const theme = useTheme();

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='22' viewBox='0 0 24 22' fill='none'>
      <path
        d='M11.5874 1.48775C11.786 1.19769 12.214 1.19769 12.4126 1.48775L20.9084 13.8975C21.1356 14.2294 20.8979 14.68 20.4958 14.68H3.50419C3.10205 14.68 2.86444 14.2294 3.09162 13.8975L11.5874 1.48775Z'
        fill={theme.palette.neutral[800]}
        stroke={JACKPOT_COLOR}
      />
      <path
        d='M11.1748 2.97093C11.572 2.3908 12.428 2.3908 12.8252 2.97093L21.321 15.3807C21.7753 16.0444 21.3001 16.9456 20.4958 16.9456H3.50419C2.69991 16.9456 2.22469 16.0444 2.67904 15.3807L11.1748 2.97093Z'
        fill={theme.palette.neutral[800]}
      />
    </svg>
  );
};

export default ArrowIcon;
