import { Info } from '@mui/icons-material';
import { Box, Typography, TypographyProps } from '@mui/material';
import { StyleObj } from '../../@types';

const styles: StyleObj = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'neutral.600',
    borderRadius: {
      xs: 1,
      md: 2,
    },
    p: {
      xs: 4,
      md: 6,
    },
  },
  icon: {
    mr: 1,
    color: 'info.main',
  },
  text: {
    typography: {
      xs: 'h5',
      md: 'h3',
    },
    color: 'primary.50',
  },
};

const EmptyStateMessage = ({ children, ...props }: TypographyProps) => {
  return (
    <Box sx={styles.container}>
      <Info sx={styles.icon} />
      <Typography sx={styles.text} {...props}>
        {children}
      </Typography>
    </Box>
  );
};

export default EmptyStateMessage;
