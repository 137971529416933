import { Box, InputBase, IconButton, Button, alpha, Slide } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { StyleObj } from '../../@types';
import { useSearchContext } from '../../contexts/SearchContext';
import useLocalization from '../../hooks/useLocalization';
import { useMarketplaceConfig } from '../../queries/marketplace';

const styles: StyleObj = {
  searchWrapper: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    width: {
      xs: '100%',
      md: '30%',
    },
    left: {
      xs: 0,
      md: '35%',
    },
    height: '100%',
    zIndex: 10,
    backgroundColor: 'primary.600',
    padding: '0 0 0 16px',
  },
  search: {
    position: 'relative',
    borderRadius: '20px',
    flexGrow: 1,
    backgroundColor: (theme) => alpha(theme.palette.common.black, 0.15),
    '&:hover': {
      backgroundColor: (theme) => alpha(theme.palette.common.black, 0.25),
    },
  },
  searchIconWrapper: {
    padding: (theme) => theme.spacing(0, 1.5),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiSvgIcon-root': {
      typography: {
        xs: 'h4',
        md: 'h2',
      },
    },
  },
  inputBase: {
    color: 'info.300',
    width: '100%',
    '& .MuiInputBase-input': {
      padding: '10px 10px 10px 0',
      // vertical padding + font size from searchIcon
      paddingLeft: 'calc(1em + 24px)',
      transition: 'width 300ms',
      typography: {
        xs: 'body3',
        md: 'inherit',
      },
    },
    '&:focus': {
      color: 'info.300',
    },
  },
  closeButton: {
    typography: 'body4',
    padding: 0,
    color: 'info.300',
    '&:hover': {
      color: 'neutral.25',
      backgroundColor: 'transparent',
    },
  },
  iconButton: {
    color: 'info.300',
    '&:hover': {
      color: 'neutral.25',
    },
    '& .MuiSvgIcon-root': {
      typography: {
        xs: 'subtitle1',
        md: 'h2',
      },
    },
  },
};

const SearchBar = () => {
  const { searchValue, handleSearch, handleClear, showSearchBar, setShowSearchBar } = useSearchContext();
  const { t } = useLocalization();
  const { data: { feedRunning } = { feedRunning: false } } = useMarketplaceConfig();

  return (
    <Slide direction='left' in={showSearchBar} mountOnEnter unmountOnExit timeout={300}>
      <Box sx={{ ...styles.searchWrapper, opacity: showSearchBar ? 1 : 0.25, transition: 'opacity 300ms' }}>
        <Box sx={styles.search}>
          <Box sx={styles.searchIconWrapper}>
            <SearchIcon />
          </Box>
          <InputBase
            sx={styles.inputBase}
            placeholder={t('search')}
            inputProps={{ 'aria-label': 'search' }}
            value={searchValue}
            onChange={(event) => handleSearch(event.target.value)}
            autoFocus
            startAdornment
            endAdornment={
              searchValue && (
                <IconButton sx={styles.iconButton} onClick={handleClear}>
                  <CloseIcon />
                </IconButton>
              )
            }
            disabled={!feedRunning}
          />
        </Box>
        <Button
          sx={styles.closeButton}
          onClick={() => {
            setShowSearchBar(false);
            handleClear();
          }}
        >
          {t('close')}
        </Button>
      </Box>
    </Slide>
  );
};

export default SearchBar;
