import { Stack, SxProps, Theme, Typography } from '@mui/material';
import { StyleObj } from '../../../@types';
import { mergeSx } from '../../../utils/styles';

const styles: StyleObj = {
  container: {
    backgroundColor: 'neutral.A100',
    px: 0.5,
    py: 0.125,
  },
  scoreText: {
    typography: {
      xs: 'body4',
      md: 'body2',
    },
    fontWeight: {
      xs: 700,
      md: 700,
    },
    color: 'neutral.25',
  },
};

type Props = {
  score?: number | null;
  sx?: SxProps<Theme>;
};

const InPlayScoreCell = ({ score, sx }: Props) => {
  if (score === null || score === undefined) return null;

  return (
    <Stack direction='row' alignItems='center' justifyContent='space-between' sx={styles.container}>
      <Typography sx={mergeSx(styles.scoreText, sx)}>{score}</Typography>
    </Stack>
  );
};

export default InPlayScoreCell;
