import { Stack } from '@mui/material';
import StyledSkeleton from '../../atoms/StyledSkeleton';

const TopCMSSkeleton = () => {
  return (
    <Stack width='100%' p={1}>
      <StyledSkeleton height={160} />
    </Stack>
  );
};

export default TopCMSSkeleton;
