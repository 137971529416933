import { Typography } from '@mui/material';
import { useSports } from '../../../queries';
import SportListItem from './SportListItem';
import { useNavigate } from 'react-router-dom';
import useLocalization from '../../../hooks/useLocalization';
import SportListSkeleton from '../skeletons/SportListSkeleton';

type Props = {
  toggleVisibility: (isOpen: boolean) => void;
};



const InPlaySportSidebar = ({ toggleVisibility }: Props) => {
  const navigate = useNavigate();
  const { t } = useLocalization();

  const { data, isLoading, isFetching } = useSports({
    queryParams: {
      isLive: true,
      includeEventCount: true,
    },
  });

  if (isLoading || isFetching) return <SportListSkeleton />;

  const navigateTo = (id: string, name: string) => {
    navigate(`/in-play/sport/${id}`, {
      state: {
        name,
      },
    });
    toggleVisibility(false);
  };

  return (
    <>
      {data?.map((sport) => (
        <SportListItem sport={sport} onClick={navigateTo} key={sport.id}>
          {!!sport.eventCount && (
            <Typography variant='body3' noWrap ml='auto' sx={{ maxWidth: '50%' }}>
              {t('nEvents', { count: sport.eventCount })}
            </Typography>
          )}
        </SportListItem>
      ))}
    </>
  );
};

export default InPlaySportSidebar;
