import { Skeleton, ListItem, Stack } from '@mui/material';
import SkeletonContainer from '../../atoms/SkeletonContainer';

const SportListSkeleton = ({ itemCount = 10 }: { itemCount?: number }) => {
  return (
    <SkeletonContainer>
      <Stack sx={{ gap: { xs: 0, md: 1 } }}>
        {[...Array(itemCount)].map((_, index) => (
          <ListItem key={index} sx={{ gap: { xs: 1, md: 2 }, py: 0.75 }}>
            <Skeleton variant='circular' sx={{ width: { xs: 20, md: 28 }, height: { xs: 20, md: 28 } }} />
            <Skeleton variant='text' sx={{ flex: 1 }} />
          </ListItem>
        ))}
      </Stack>
    </SkeletonContainer>
  );
};

export default SportListSkeleton;
