export type ErrorObject<U = Record<string, unknown>> = {
  code: string;
  message: string;
  showToUser: boolean;
  additionalData?: U;
};

export const isFeedApiError = <U = Record<string, unknown>>(error: unknown): error is ErrorObject<U> => {
  return typeof error === 'object' && error !== null && 'code' in error && 'message' in error && 'showToUser' in error;
};
