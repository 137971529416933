import { Fragment } from 'react';
import { PublishedEventListItem } from '../../../@types';
import useLocalization from '../../../hooks/useLocalization';
import Heading from '../../atoms/Heading';
import Carousel from '../../organisms/Carousel';
import EventCard from '../EventCard';

type Props = {
  events: PublishedEventListItem[];
};

export const TournamentCarousel = ({ events }: Props) => {
  const { t } = useLocalization();
  const tournament = events[0].tournament;

  return (
    <Fragment key={tournament.id}>
      <Heading to={`${tournament.competition.sportId}/tournaments/${tournament.id}`}>
        <Heading.Title>{tournament.parentTournamentName ?? tournament.name}</Heading.Title>
        <Heading.Link>{t('goToMatches')}</Heading.Link>
      </Heading>
      <Carousel>
        {events.map((event) => (
          <EventCard key={event.id} event={event} />
        ))}
      </Carousel>
    </Fragment>
  );
};
