import { Grid, Stack, StackProps, SxProps, Theme, Typography, TypographyProps } from '@mui/material';
import { PropsWithChildren } from 'react';
import { StyleObj } from '../../@types';
import { ChevronRight } from '@mui/icons-material';
import { To, useNavigate } from 'react-router-dom';
import { mergeSx } from '../../utils/styles';

type Styles = {
  cursorPointer: boolean;
};

type HeadingProps = PropsWithChildren &
  StackProps & {
    to?: To;
    wrapperStyles?: SxProps<Theme>;
  };

const makeStyles = ({ cursorPointer }: Styles): StyleObj => ({
  heading: (theme) => ({
    cursor: cursorPointer ? 'pointer' : 'default',
    padding: {
      xs: '6px 8px',
      md: '8px 16px',
      lg: '16px 24px',
    },
    borderTop: '1px solid',
    borderColor: 'info.600',
    background: `linear-gradient(90deg, ${theme.palette.primary.main} 10.78%, rgba(20, 128, 92, 0.00) 90.63%)`,
    width: '100%',
  }),
  headingWrapper: {
    mt: 0,
  },
});

const Heading = ({ children, to, onClick, sx, wrapperStyles, ...rest }: HeadingProps) => {
  const navigate = useNavigate();

  const styles = makeStyles({
    cursorPointer: Boolean(to),
  });

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    to && navigate(to);

    onClick?.(e);
  };

  return (
    <Grid item xs={12} sx={mergeSx(styles.headingWrapper, wrapperStyles)}>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        onClick={handleClick}
        sx={mergeSx(styles.heading, sx)}
        {...rest}
      >
        {children}
      </Stack>
    </Grid>
  );
};

const componentStyles: StyleObj = {
  title: {
    typography: {
      xs: 'h5',
      md: 'h4',
      lg: 'h3',
    },
    letterSpacing: {
      xs: 1,
      md: 0.1,
    },
  },
  subtitle: {
    typography: {
      xs: 'body3',
      md: 'subtitle2',
    },
    fontWeight: 500,
  },
  linkIcon: {
    fontSize: {
      xs: 18,
      md: 20,
      lg: 24,
    },
  },
};

const Title = ({ children, ...rest }: TypographyProps) => (
  <Typography sx={componentStyles.title} {...rest}>
    {children}
  </Typography>
);

const Subtitle = ({ children, ...rest }: TypographyProps) => (
  <Typography sx={componentStyles.subtitle} color='info.main' {...rest}>
    {children}
  </Typography>
);

const Link = ({ children }: PropsWithChildren) => (
  <Stack direction='row' alignItems='center' spacing={0.5} textAlign='right'>
    <Heading.Title color='secondary'>{children}</Heading.Title>
    <ChevronRight sx={componentStyles.linkIcon} color='secondary' />
  </Stack>
);

Heading.Title = Title;
Heading.Subtitle = Subtitle;
Heading.Link = Link;

export default Heading;
