import { Stack, StackProps } from '@mui/material';
import StyledSkeleton from '../../atoms/StyledSkeleton';
import { mergeSx } from '../../../utils/styles';

const JackpotSkeleton = ({ sx, ...props }: StackProps) => {
  return (
    <Stack
      {...props}
      sx={mergeSx({ '&.MuiStack-root': { minHeight: 0, alignItems: 'end', mt: 0.25, mx: { xs: 0, md: 0.25 } } }, sx)}
      width='100%'
      spacing={0.25}
    >
      {Array.from({ length: 3 }).map((_, index) => (
        <StyledSkeleton key={index} height={24} width='100%' />
      ))}
    </Stack>
  );
};

export default JackpotSkeleton;
