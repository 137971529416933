import { Box, IconButton, Typography, Popper, ClickAwayListener, Paper } from '@mui/material';
import { useMiniStatsWidget } from '../../../contexts/MiniStatsWidgetContext';
import { StyleObj, Scoreboard, Statistic } from '../../../@types';
import BarChart from '@mui/icons-material/BarChart';
import CornerIcon from '../../../components/icons/CornerIcon';
import CardIcon from '../../../components/icons/CardIcon';
import SportsIcon from '../../atoms/SportsIcon';
import { useGetEventScoreboard } from '../../../queries';
import { mergeSx } from '../../../utils/styles';

const styles: StyleObj = {
  widgetContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    height: '100%',
  },
  popover: {
    px: {
      xs: 1.5,
      md: 2,
    },
    py: { xs: 1, md: 1.5 },
    backgroundColor: 'background.paper',
  },
  statsGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    placeItems: 'center',
    rowGap: 0.5,
    columnGap: 2,
    width: '100%',
    '& svg': {
      mb: {
        xs: 0.5,
        md: 1,
      },
    },
  },
  statsRow: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  statValue: {
    color: 'neutral.800',
    typography: {
      xs: 'body3',
      md: 'body2',
    },
  },
  iconButton: {
    p: 0,
    pl: {
      xs: 0,
      md: 1,
    },
    ':hover, :active': {
      color: 'info.main',
    },
    '& .MuiSvgIcon-root': {
      fontSize: {
        xs: 12,
        md: 20,
      },
    },
  },
};

const STAT_TYPES = [
  { key: 'corners', icon: <CornerIcon stroke='currentColor' width={20} /> },
  { key: 'yellowCards', icon: <CardIcon fill='#FEDA2B' width={12} height={20} /> },
  { key: 'redCards', icon: <CardIcon fill='#FF4B55' width={12} height={20} /> },
  { key: 'goals', icon: <SportsIcon name='soccer' sx={{ width: 16, mt: 0.25 }} /> },
];

const getEventStats = (scoreboard?: Scoreboard | null) => {
  const getStats = (team?: { statistics?: Statistic }, goals = 0) => [
    team?.statistics?.cornerCount || 0,
    team?.statistics?.yellowCardCount || 0,
    team?.statistics?.redCardCount || 0,
    goals,
  ];

  return {
    homeStats: getStats(scoreboard?.participants.home, scoreboard?.mainEventPart?.valueHome || 0),
    awayStats: getStats(scoreboard?.participants.away, scoreboard?.mainEventPart?.valueAway || 0),
  };
};

type MiniStatsWidgetProps = {
  eventId: string;
};

const MiniStatsWidget = ({ eventId }: MiniStatsWidgetProps) => {
  const { isOpen, anchorEl, toggle } = useMiniStatsWidget(eventId);

  const { data: scoreboard } = useGetEventScoreboard(eventId);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    toggle(event.currentTarget);
  };

  const handleClose = () => {
    toggle(null);
  };

  const { homeStats, awayStats } = getEventStats(scoreboard);

  return (
    <Box sx={styles.widgetContainer}>
      <IconButton
        size='small'
        sx={mergeSx(styles.iconButton, { color: isOpen ? 'neutral.100' : 'neutral.300' })}
        onClick={handleClick}
        disableRipple
      >
        <BarChart />
      </IconButton>

      <Popper
        open={isOpen}
        anchorEl={anchorEl}
        placement='bottom'
        modifiers={[{ name: 'offset', options: { offset: [0, 12] } }]}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Paper
            sx={styles.popover}
            onClick={(e) => {
              e.stopPropagation();
              handleClose();
            }}
          >
            <Box sx={styles.statsGrid}>
              {STAT_TYPES.map((stat) => (
                <Box key={stat.key}>{stat.icon}</Box>
              ))}

              {homeStats.map((value, index) => (
                <Typography key={`home-${index}`} sx={styles.statValue}>
                  {value}
                </Typography>
              ))}

              {awayStats.map((value, index) => (
                <Typography key={`away-${index}`} sx={styles.statValue}>
                  {value}
                </Typography>
              ))}
            </Box>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </Box>
  );
};

export default MiniStatsWidget;
