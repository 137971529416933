import { useQuery } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import { Market, PublishedEventListItem, PublishedEventListWithTopMarketType } from '../../@types';
import { QUERY_KEYS } from '../../constants';
import { MessageBetType } from '../../helpers/betslipMessageParser';
import eventListItemUpdater from '../../helpers/eventListItemUpdater';
import topMarketsUpdater from '../../helpers/eventTopMarketsUpdater';
import useWebsocket, { MessageType } from '../../hooks/useWebsocket';
import { usePublishedEvents } from '../../queries';
import { getData } from '../../utils/api';
import Heading from '../atoms/Heading';
import EventsTable from '../organisms/tables/EventsTable';
import useLocalization from '../../hooks/useLocalization';
import FeaturedTableSkeleton from './skeletons/FeaturedTableSkeleton';

type Props = {
  sportId?: string;
};

const InPlayPreviewTable = ({ sportId }: Props) => {
  const { t } = useLocalization();

  const { data: liveEventsCount, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.liveEventsCount],
    queryFn: (): Promise<{ count: number }> => getData('events/live/count'),
  });

  const { data: upcomingLiveEventsData, isSuccess } = usePublishedEvents({
    queryKey: [QUERY_KEYS.upcomingLiveEvents],
    queryParams: {
      limit: 10,
      sportId,
      isLive: true,
      count: false,
    },
    options: {
      enabled: !!sportId,
    },
  });

  const [events, setEvents] = useState<PublishedEventListItem[]>([]);
  const [topMarkets, setTopMarkets] = useState<Record<string, Market[] | null>>({});

  const updaterCallback = useCallback((data: Record<string, MessageType<MessageBetType>>[]) => {
    setEvents((prevState) => {
      const newEvents = [...(prevState || [])];

      data.forEach((message) => {
        const updatedEventId = Object.keys(message)[0];
        const eventIndex = newEvents.findIndex((event) => event.id === updatedEventId);
        if (eventIndex === -1) return;
        const updatedEvent = eventListItemUpdater(newEvents[eventIndex], message[updatedEventId]);
        newEvents[eventIndex] = updatedEvent;
      });

      return newEvents;
    });
  }, []);

  const { joinRoom } = useWebsocket<MessageType<MessageBetType>>({
    callback: updaterCallback,
  });

  const topMarketsUpdaterCallback = (data: Record<string, MessageType<MessageBetType>>[]) => {
    setTopMarkets((prevState) => {
      let newMarkets = { ...prevState };

      data.forEach((message) => {
        const updatedEventId = Object.keys(message)[0];
        const marketsToUpdate = newMarkets[updatedEventId];
        if (!marketsToUpdate) return;
        const updatedMarkets = topMarketsUpdater(marketsToUpdate, message[updatedEventId]);

        newMarkets = { ...newMarkets, [updatedEventId]: updatedMarkets };
      });

      return newMarkets;
    });
  };

  const { joinRoom: joinTopMarketRoom } = useWebsocket<MessageType<MessageBetType>>({
    callback: topMarketsUpdaterCallback,
  });

  const initializeTopMarkets = useCallback(
    (data?: PublishedEventListWithTopMarketType): Record<string, Market[] | null> => {
      const markets: Record<string, Market[] | null> = {};

      data?.items?.forEach((event) => {
        joinRoom(`${event.id}_reduced`);

        if (event.topMarkets?.[0]) {
          joinTopMarketRoom(`${event.id}_${event.topMarkets[0]?.marketType.id}`);
          markets[event.id] = event.topMarkets;
        }
      });
      return markets;
    },
    [joinRoom, joinTopMarketRoom]
  );

  useEffect(() => {
    if (isSuccess && upcomingLiveEventsData) {
      setEvents(upcomingLiveEventsData.items || []);
      setTopMarkets(initializeTopMarkets(upcomingLiveEventsData));
    }
  }, [isSuccess, upcomingLiveEventsData, initializeTopMarkets]);

  if (isLoading || !sportId) {
    return <FeaturedTableSkeleton />;
  }

  return (
    <>
      <Heading to='../in-play'>
        <Heading.Title>{t('live')}</Heading.Title>
        <Heading.Link>{t('nEvents', { count: liveEventsCount?.count ?? 0 })}</Heading.Link>
      </Heading>
      {!!events.length && sportId && (
        <EventsTable
          data={events.map((event) => ({
            ...event,
            topMarket: topMarkets[event.id],
          }))}
          showTableHead
          topMarketTypes={upcomingLiveEventsData?.topMarketTypes}
          outcomeTypes={upcomingLiveEventsData?.topMarketTypes?.[0]?.outcomeTypes}
          inPlay
        />
      )}
    </>
  );
};

export default InPlayPreviewTable;
