import { Grid } from '@mui/material';
import { useSports } from '../../queries';
import FeaturedEventsCarousel from '../molecules/FeaturedEventsCarousel';
import Footer from '../molecules/Footer';
import InPlayPreviewTable from '../molecules/InPlayPreviewTable';
import JackpotList from '../molecules/JackpotList';
import TopOfferList from '../molecules/TopOfferList';
import UpcomingEventsTable from '../molecules/UpcomingEventsTable';
import CMSTopContent from '../organisms/CMSTopContent';
import FeaturedTournaments from '../molecules/featured-tournaments/FeaturedTournaments';
import FeaturedSport from '../molecules/FeaturedSport';

const SportsPage = () => {
  const { data: sports } = useSports({
    queryParams: {
      isLive: false,
    },
  });

  const featuredSport = sports?.[0];

  return (
    <Grid container>
      <JackpotList direction='row' overflow='auto' />
      <CMSTopContent />
      <FeaturedSport featuredSport={featuredSport} />
      <FeaturedTournaments />
      <FeaturedEventsCarousel sportId={featuredSport?.id} />
      <InPlayPreviewTable sportId={featuredSport?.id} />
      <UpcomingEventsTable sportId={featuredSport?.id} sportName={featuredSport?.name} />
      <TopOfferList />
      <Footer />
    </Grid>
  );
};

export default SportsPage;
