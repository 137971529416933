import { List, ListItem, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { DrawerContentProps } from '../../../@types';
import useLocalization from '../../../hooks/useLocalization';
import { useSports } from '../../../queries';
import SportListItem from './SportListItem';
import SportListSkeleton from '../skeletons/SportListSkeleton';

const BettingDrawerContent = ({ toggleVisibility }: DrawerContentProps) => {
  const navigate = useNavigate();
  const { t } = useLocalization();

  const {
    data: sportsData,
    isLoading,
    isSuccess,
  } = useSports({
    queryParams: {
      isLive: false,
    },
  });

  const navigateTo = (id: string, name: string) => {
    navigate(`/sports/${id}`, {
      state: {
        name,
      },
    });
    toggleVisibility(false);
  };

  return (
    <List>
      <ListItem>
        <Typography color='info.main' variant='h5' textTransform='uppercase'>
          {t('fullList')}
        </Typography>
      </ListItem>
      {isLoading ? (
        <SportListSkeleton itemCount={20} />
      ) : (
        isSuccess && sportsData.map((sport) => <SportListItem key={sport.id} sport={sport} onClick={navigateTo} />)
      )}
    </List>
  );
};

export default BettingDrawerContent;
