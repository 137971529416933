import Dialog from '@mui/material/Dialog';
import Drawer from '@mui/material/Drawer';
import React, { createContext, useContext, useState } from 'react';
import useBreakpoints from './useBreakpoints';

interface IModalContext {
  openModal: (modalContent: React.ReactElement) => void;
  closeModal: () => void;
}

const ModalContext = createContext<IModalContext | null>(null);

interface ProviderProps {
  children: React.ReactElement;
}

export const ModalProvider = ({ children }: ProviderProps) => {
  const [toggleModal, setToggleModal] = useState<boolean>(false);
  const [content, setContent] = useState<React.ReactElement | null>(null);
  const { isMobile } = useBreakpoints();

  const openModal = (modalContent: React.ReactElement) => {
    setToggleModal(true);
    setContent(modalContent);
  };

  const closeModal = () => {
    setToggleModal(false);
    setContent(null);
  };

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      {children}
      {isMobile ? (
        <Drawer
          anchor='bottom'
          open={toggleModal}
          onClose={closeModal}
          PaperProps={{
            sx: {
              maxHeight: '90vh',
            },
          }}
        >
          {content}
        </Drawer>
      ) : (
        <Dialog open={toggleModal} onClose={closeModal} maxWidth={false}>
          {content}
        </Dialog>
      )}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const context = useContext(ModalContext);

  if (context === null) {
    throw new Error('useModal must be used within a Modal');
  }

  return context;
};
