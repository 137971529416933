import { Typography, TypographyProps } from '@mui/material';
import type { Market } from '../../@types';
import useLocalization from '../../hooks/useLocalization';
import { getAccordionTitle } from '../../helpers/renderMarketAccordion';
import { formatSpecialValue } from '../../utils/specialValueFormatters';
import { SpecialValuesDisplayProps } from '../../types/specialValues';

const SpecialValues = ({
  outcomeName,
  sportId,
  specialValues,
  marketTypeName,
  specialValueModel,
  wrapInParenthesis = true,
  numericOnly = false,
  ...rest
}: SpecialValuesDisplayProps) => {
  const { t } = useLocalization();

  const formattedDisplayValue = formatSpecialValue({
    marketTypeName,
    outcomeName,
    sportId,
    specialValues,
    numericOnly,
    wrapInParenthesis,
    t,
    specialValueModel,
  });

  return (
    <Typography component='span' variant='inherit' noWrap {...rest}>
      {formattedDisplayValue}
    </Typography>
  );
};

type OutcomeProps = TypographyProps & {
  outcomeName: string;
  outcomeShortName?: string;
};

const Outcome = ({ outcomeName, outcomeShortName, ...rest }: OutcomeProps) => {
  const { getLocalizedOutcomeName } = useLocalization();

  return (
    <Typography variant='inherit' component='span' {...rest} textAlign='center'>
      {outcomeShortName ?? getLocalizedOutcomeName(outcomeName)}
    </Typography>
  );
};

type MarketProps = TypographyProps & {
  market?: Market;
  marketName?: string;
  marketType?: string;
  marketShortName?: string;
};

const Market = ({ market, marketName, marketType, marketShortName, ...rest }: MarketProps) => {
  const { getLocalizedMarketName } = useLocalization();
  const { marketType: marketTypeData, shortName: marketShort } = market ?? {};
  const shortName = marketShortName ?? marketShort;
  if (shortName) {
    return (
      <Typography variant='body2' component='span' {...rest}>
        {shortName}
      </Typography>
    );
  }

  const { name: marketTypeName, eventPartName } = marketTypeData ?? {};
  let marketDisplayName = '';
  if (marketTypeName?.toLowerCase().includes('handicap')) {
    marketDisplayName = `${marketTypeName}, ${eventPartName}`;
  } else {
    marketDisplayName = market ? getAccordionTitle(market) : marketName ?? '';
  }
  const localizedName = getLocalizedMarketName(marketDisplayName, marketTypeName ?? marketType);

  return (
    <Typography variant='body2' component='span' {...rest}>
      {localizedName}
    </Typography>
  );
};

export const BetDetails = {
  Outcome,
  SpecialValues,
  Market,
};
