import { createContext, type ReactNode, useCallback, useContext, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getData } from '../utils/api';
import { QUERY_KEYS } from '../constants';
import { EventsData, SportEvents } from '../@types/search';
import { useDebounce } from '@uidotdev/usehooks';

const DEBOUNCE_TIMEOUT = 500;

interface SearchContextProps {
  searchValue: string;
  handleSearch: (value: string) => void;
  handleClear: () => void;
  searchResults: SportEvents[] | undefined;
  isLoading: boolean;
  isError: boolean;
  showSearchBar: boolean;
  setShowSearchBar: (value: boolean) => void;
  hasNoResults: boolean;
}

const SearchContext = createContext<SearchContextProps | undefined>(undefined);

interface SearchProviderProps {
  children: ReactNode;
}

export const SearchProvider = ({ children }: SearchProviderProps) => {
  const [searchValue, setSearchValue] = useState('');
  const [showSearchBar, setShowSearchBar] = useState(false);

  const debouncedSearchValue = useDebounce(searchValue, DEBOUNCE_TIMEOUT);

  const handleSearch = useCallback((searchValue: string) => {
    setSearchValue(searchValue);
  }, []);

  const handleClear = useCallback(() => {
    setSearchValue('');
  }, []);

  const {
    data: searchResults,
    isLoading,
    isError,
  } = useQuery({
    queryKey: [QUERY_KEYS.searchResults, debouncedSearchValue],
    queryFn: (): Promise<EventsData> =>
      getData('events/search', {
        search: debouncedSearchValue,
      }),
    select: (data) => data.items ?? [],
    enabled: debouncedSearchValue.trim().length > 2,
  });

  const hasNoResults = !isLoading && !isError && searchResults?.length === 0;

  const value = {
    searchValue,
    handleSearch,
    searchResults,
    isLoading,
    isError,
    showSearchBar,
    setShowSearchBar,
    handleClear,
    hasNoResults,
  };

  return <SearchContext.Provider value={value}>{children}</SearchContext.Provider>;
};

export const useSearchContext = (): SearchContextProps => {
  const context = useContext(SearchContext);
  if (context === undefined) {
    throw new Error('useSearch must be used within a SearchProvider');
  }
  return context;
};
