import i18next from 'i18next';
import { useEffect } from 'react';
import { DEFAULT_LANGUAGE } from '../../localization/utils';
import { Box } from '@mui/material';

const scriptId = 'STATSCOREWidgetsEmbederScript';

// Helper function to dynamically load the script
const loadScript = (): Promise<void> => {
  return new Promise((resolve, reject) => {
    if (document.getElementById(scriptId)) {
      resolve(); // Script already loaded
      return;
    }

    const script = document.createElement('script');
    script.src = 'https://wgt-s3-cdn.statscore.com/bundle/EmbederESM.js';
    script.type = 'module';
    script.async = true;
    script.id = scriptId;

    script.onload = () => resolve();
    script.onerror = () => reject(new Error('Failed to load STATSCORE script'));

    document.body.appendChild(script);
  });
};

// Helper function to initialize the STATSCORE widget
const initializeWidget = (eventId: string, configurationId: string, language: string) => {
  if (!window.STATSCOREWidgets) {
    console.error('STATSCOREWidgets is not available');
    return;
  }

  window.STATSCOREWidgets.onLoad((err) => {
    if (err) {
      console.error('STATSCOREWidgets failed to load:', err);
      return;
    }

    const element = document.getElementById('myWidget');
    if (!element) {
      console.error('Widget container not found');
      return;
    }

    const inputData = { eventId, language, configurationId };
    const options = {};

    if (typeof window.STATSCOREWidgets.WidgetGroup === 'function') {
      return new window.STATSCOREWidgets.WidgetGroup(element, configurationId, inputData, options);
    } else {
      console.error('STATSCOREWidgets.Widget is not a function');
    }
  });
};

interface StatscoreWidgetProps {
  eventId: string;
  configurationId: string;
}

const StatscoreWidget = ({ eventId, configurationId }: StatscoreWidgetProps) => {
  const language = i18next.language ?? DEFAULT_LANGUAGE;
  useEffect(() => {
    loadScript()
      .then(() => initializeWidget(eventId, configurationId, language))
      .catch(console.error);
  }, [configurationId, eventId, language]);

  return <Box id='myWidget' />;
};

export default StatscoreWidget;
