import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { SanityImage } from '../../@types';
import { getSanityImage } from '../../helpers/sanity';
import { useSanity } from '../../hooks/useSanity';

const MetaTags: React.FC = () => {
  const [metaData, setMetaData] = useState({
    title: 'Sportsbook',
    description: '',
    image: '',
  });

  const { data: logo } = useSanity<{ image: SanityImage }>('Logo');
  const sanityImage = getSanityImage(logo?.[0]?.image);
  useEffect(() => {
    const hostname = window.location.hostname;
    if (hostname.includes('top-ks')) {
      setMetaData((prevMetaData) => ({
        ...prevMetaData,
        title: '365',
      }));
    }
    if (sanityImage) {
      setMetaData((prevMetaData) => ({
        ...prevMetaData,
        image: sanityImage,
      }));
    }
  }, [sanityImage]);

  return (
    <Helmet>
      <title>{metaData.title}</title>
      <meta name='description' content={metaData.description} />
      <meta property='og:title' content={metaData.title} />
      <meta property='og:description' content={metaData.description} />
      <meta property='og:image' content={metaData.image} />
    </Helmet>
  );
};

export default MetaTags;
