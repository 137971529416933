import { CssBaseline } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AuthProvider } from '@neotech-solutions-org/neofusion-fe-shared';
import { SnackbarProvider } from 'notistack';
import { Suspense, useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import ReactQueryProvider from './ReactQueryProvider';
import router from './Routes';
import StyledSnackbar from './components/atoms/StyledSnackbar';
import { KEYCLOAK_CONFIG, KEYCLOAK_INIT_OPTIONS } from './constants/keycloak';
import { BetslipProvider } from './contexts/BetslipContext';
import { CustomThemeProvider } from './contexts/ThemeContext';
import useBreakpoints from './hooks/useBreakpoints';
import { ModalProvider } from './hooks/useDialog';
import { isIos } from './utils/isIos';
import './App.css';
import { LanguageInstanceProvider } from './localization/LanguageInstanceProvider';
import { initializeGA } from './helpers/googleAnalytics';
import { HelmetProvider } from 'react-helmet-async';
import MetaTags from './components/meta-tags/MetaTags';
import GrowthBookProviderWrapper from './GrowthBookProviderWrapper';
import { GROWTHBOOK_API_HOST, GROWTHBOOK_CLIENT_KEY } from './constants/growthBook';
import useManifestLoad from './hooks/useManifestLoad';

const App = () => {
  useManifestLoad();
  const { isMobile } = useBreakpoints();

  useEffect(() => {
    initializeGA();
    if (isIos()) {
      const viewportMeta = document.querySelector('meta[name="viewport"]');
      if (viewportMeta) {
        viewportMeta.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1');
      }
    }
  }, []);

  return (
    <HelmetProvider>
      <AuthProvider keycloakConfig={KEYCLOAK_CONFIG} keycloakInitOptions={KEYCLOAK_INIT_OPTIONS}>
        <GrowthBookProviderWrapper apiHost={GROWTHBOOK_API_HOST} clientKey={GROWTHBOOK_CLIENT_KEY}>
          <ReactQueryProvider>
            <CustomThemeProvider>
              <ReactQueryDevtools initialIsOpen={false} />
              <MetaTags />
              <LanguageInstanceProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <SnackbarProvider
                    Components={{
                      success: StyledSnackbar,
                      info: StyledSnackbar,
                      warning: StyledSnackbar,
                    }}
                    classes={{ containerAnchorOriginTopCenter: 'snackbar-container' }}
                    preventDuplicate
                    anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
                    dense={isMobile}
                    hideIconVariant
                    maxSnack={5}
                    autoHideDuration={3000}
                  >
                    <ModalProvider>
                      <BetslipProvider>
                        <CssBaseline />
                        <Suspense>
                          <RouterProvider router={router} />
                        </Suspense>
                      </BetslipProvider>
                    </ModalProvider>
                  </SnackbarProvider>
                </LocalizationProvider>
              </LanguageInstanceProvider>
            </CustomThemeProvider>
          </ReactQueryProvider>
        </GrowthBookProviderWrapper>
      </AuthProvider>
    </HelmetProvider>
  );
};

export default App;
