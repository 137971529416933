import { Stack } from '@mui/material';
import StyledSkeleton from '../../atoms/StyledSkeleton';
import useBreakpoints from '../../../hooks/useBreakpoints';

const FeaturedCarouselSkeleton = () => {
  const { isTablet } = useBreakpoints();

  return (
    <Stack spacing={1} width={'100%'} margin={1}>
      <StyledSkeleton variant='rectangular' sx={{ height: { xs: 26, md: 57 } }} />
      <Stack
        direction='row'
        justifyContent={'space-evenly'}
        width={'100%'}
        spacing={{ xs: 0.5, md: 2 }}
        p={{ xs: 0, md: 1.5 }}
      >
        {Array.from({ length: isTablet ? 1 : 3 }).map((_, index) => (
          <Stack key={index} direction='column' spacing={1} width='100%'>
            <StyledSkeleton variant='rectangular' height={isTablet ? 65 : 80} width='100%' />
            <Stack direction='row' spacing={1}>
              {Array.from({ length: 3 }).map((_, index) => (
                <StyledSkeleton key={index} variant='rectangular' height={isTablet ? 36 : 42} width={'33%'} />
              ))}
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

export default FeaturedCarouselSkeleton;
