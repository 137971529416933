import { SanityTopItem } from '../../@types';
import { Content, useSanity } from '../../hooks/useSanity';
import StyledSkeleton from '../atoms/StyledSkeleton';
import CMSCarouselCasinoItem from '../molecules/CMSCarouselCasinoItem';
import CMSCarouselItem from '../molecules/CMSCarouselItem';
import TopCMSSkeleton from '../molecules/skeletons/TopCMSSkeleton';
import Carousel from './Carousel';

interface Props {
  scope?: 'sportsbook' | 'casino';
}

const styles = {
  casinoSkeleton: {
    height: '20dvh',
    width: '90dvw',
    maxWidth: '1400px',
    backgroundColor: 'neutral.600',
  },
};

const CMSTopContent = ({ scope = 'sportsbook' }: Props) => {
  let contentType: Content = 'PromotionalTopContent';
  if (scope === 'casino') {
    contentType = 'CasinoPromotionalTopContent';
  }
  const { data: cmsContent, isLoading } = useSanity<SanityTopItem>(contentType);

  let Component = CMSCarouselItem;
  if (scope === 'casino') {
    Component = CMSCarouselCasinoItem;
  }
  if (isLoading) {
    if (scope === 'casino') {
      return <StyledSkeleton sx={styles.casinoSkeleton} />;
    }
    return <TopCMSSkeleton />;
  }

  return <Carousel cms>{cmsContent?.map((item) => <Component item={item} key={item.title} />)}</Carousel>;
};

export default CMSTopContent;
