import { ExpandMore } from '@mui/icons-material';
import { AccordionDetails, AccordionSummary, Accordion as MuiAccordion, Skeleton } from '@mui/material';
import { useState } from 'react';
import { StyleObj } from '../../@types';
import FavoritesCheckbox from '../atoms/FavoritesCheckbox';
import useBreakpoints from '../../hooks/useBreakpoints';
import { BetDetails } from '../atoms/BetDetails';
import useMobileOnHold from '../../hooks/useMobileOnHold';

type AccordionProps = {
  title: string;
  children: React.ReactNode;
  defaultExpanded?: boolean;
  marketId?: string;
  isFavoriteMarket?: boolean;
  showFavorites?: boolean;
  marketTypeName?: string;
};

const styles: StyleObj = {
  accordion: {
    boxShadow: 'none',
    borderRadius: 0,
    '&.Mui-expanded': {
      my: 0,
      '&:before': {
        opacity: 1,
      },
    },
    '&.MuiAccordion-root': {
      borderRadius: 0,
    },
  },
  accordionSummary: {
    background: (theme) => `linear-gradient(150deg, ${theme.palette.primary.main}50 0%, transparent 100%)`,
    borderTop: '1px solid',
    borderColor: 'primary.main',
    minHeight: 0,
    '&.Mui-expanded': {
      minHeight: 0,
    },
    '&:hover': {
      '& .MuiAccordionSummary-content span': {
        color: 'info.main',
      },
    },
    '& .MuiAccordionSummary-content': {
      my: {
        xs: 1.5,
        lg: 2,
      },
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      my: {
        xs: 1.5,
        lg: 2,
      },
    },
    px: {
      xs: 1.5,
      md: 2,
    },
  },
  accordionDetails: {
    p: 0,
    '& .MuiPaper-root': {
      borderRadius: 0,
    },
  },
  expandMoreIcon: {
    color: 'neutral.50',
  },
  marketName: {
    color: 'neutral.50',
    typography: {
      xs: 'h5',
      md: 'h4',
    },
  },
  checkbox: {
    ml: 1,
    p: 0,
  },
  uncheckedIcon: { color: 'neutral.400' },
};

const MarketAccordion = ({
  title,
  children,
  defaultExpanded,
  marketId,
  isFavoriteMarket,
  showFavorites,
  marketTypeName,
}: AccordionProps) => {
  const [expanded, setExpanded] = useState(defaultExpanded);
  const [hovered, setHovered] = useState(false);
  const { isMobile } = useBreakpoints();
  const { holdHandlers, isChecked, handleFavorite } = useMobileOnHold(!!isFavoriteMarket, 'market-types', marketId);
  const handleExpand = () => {
    setExpanded(!expanded);
  };

  const showFavoritesCheckbox = showFavorites && marketId && (hovered || !!isChecked);

  if (!title) return <Skeleton variant='rectangular' width='100%' height={40} animation='wave' />;

  return (
    <MuiAccordion
      sx={styles.accordion}
      expanded={expanded}
      onMouseEnter={() => !isMobile && setHovered(true)}
      onMouseLeave={() => !isMobile && setHovered(false)}
      onClick={handleExpand}
    >
      <AccordionSummary
        expandIcon={<ExpandMore onClick={handleExpand} sx={styles.expandMoreIcon} />}
        aria-controls='markets-content'
        id='markets-header'
        sx={styles.accordionSummary}
        {...holdHandlers}
        onContextMenu={(e) => e.preventDefault()}
        onClick={(e) => e.stopPropagation()}
      >
        <BetDetails.Market marketName={title} marketType={marketTypeName} sx={styles.marketName} />
        {showFavoritesCheckbox && (
          <FavoritesCheckbox
            isChecked={!!isChecked}
            uncheckedColor='neutral.50'
            variant={isMobile ? 'small' : 'medium'}
            onClick={(e) => e.stopPropagation()}
            onChange={handleFavorite}
            sx={styles.checkbox}
          />
        )}
      </AccordionSummary>
      <AccordionDetails sx={styles.accordionDetails}>{children}</AccordionDetails>
    </MuiAccordion>
  );
};

export default MarketAccordion;
