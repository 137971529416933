import { Stack, Typography } from '@mui/material';
import React from 'react';
import FavoritesCheckbox from '../atoms/FavoritesCheckbox';
import { PublishedEventListItem, StyleObj } from '../../@types';
import { useParams } from 'react-router-dom';
import useMobileOnHold from '../../hooks/useMobileOnHold';
import { formatMatchTime } from '../../utils';

const styles = {
  participantContainer: { alignItems: 'start', flex: '1 1 auto', minWidth: 0 },
  scoreContainer: { flexDirection: 'row', alignItems: 'end', flex: '0 0 auto' },
} satisfies StyleObj;

const FavoriteEvent = ({
  event,
  handleEventClick,
}: {
  event: PublishedEventListItem;
  handleEventClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, id: string) => void;
}) => {
  const { eventId } = useParams();
  const isSelectedEvent = event.id === eventId;
  const { isChecked, handleFavorite } = useMobileOnHold(!!event.favourite, 'events', event.id);

  return (
    <Stack
      direction='row'
      gap={1}
      py={1.25}
      pl={3}
      pr={1}
      key={event.id}
      onClick={(e) => handleEventClick(e, event.id)}
    >
      <Typography variant='body3' width={35}>
        {formatMatchTime(event.matchTime, event.tournament.sport.id)}
      </Typography>
      <Stack sx={styles.participantContainer}>
        <Typography variant='body3' fontWeight={isSelectedEvent ? 700 : 400} noWrap maxWidth='100%'>
          {event.participants?.home?.name || event.participants?.home?.shortName}
        </Typography>
        <Typography variant='body3' fontWeight={isSelectedEvent ? 700 : 400} noWrap maxWidth='100%'>
          {event.participants?.away?.name || event.participants?.away?.shortName}
        </Typography>
      </Stack>
      <Stack sx={styles.scoreContainer}>
        <Stack>
          <Typography
            variant='body3'
            fontWeight={700}
            noWrap
            textAlign='right'
            color={isSelectedEvent ? 'secondary' : 'inherit'}
          >
            {event.mainEventPartScore?.valueHome}
          </Typography>
          <Typography
            variant='body3'
            fontWeight={700}
            noWrap
            textAlign='right'
            color={isSelectedEvent ? 'secondary' : 'inherit'}
          >
            {event.mainEventPartScore?.valueAway}
          </Typography>
        </Stack>
        <FavoritesCheckbox isChecked={isChecked} variant='medium' onChange={handleFavorite} />
      </Stack>
    </Stack>
  );
};

export default FavoriteEvent;
