import { useRef, useCallback, useState } from 'react';
import useBreakpoints from './useBreakpoints';
import { useToggleFavorite } from '../mutations';
import { EntityType } from '../@types';

type HoldEventHandlers = {
  onTouchStart: (e: React.TouchEvent) => void;
  onTouchEnd: () => void;
  onTouchCancel: () => void;
  onTouchMove: (e: React.TouchEvent) => void;
};

const useMobileOnHold = (isFavoriteEntity: boolean, entityType: EntityType, entityId?: string) => {
  const pressTimer = useRef<NodeJS.Timeout | null>(null);
  const startY = useRef<number | null>(null);
  const { isMobile } = useBreakpoints();
  const { toggleFavorite } = useToggleFavorite();
  const [isChecked, setIsChecked] = useState(isFavoriteEntity); // update checkbox on change immediately

  const handleFavorite = useCallback(() => {
    setIsChecked((prevChecked) => !prevChecked);
    entityId && toggleFavorite(entityId, entityType, !isChecked);
  }, [entityType, isChecked, entityId, toggleFavorite]);

  const startHold = useCallback(
    (e: React.TouchEvent) => {
      if (!isMobile) return; // Ensure it's mobile-only
      e.stopPropagation();
      startY.current = e.touches[0].clientY;
      pressTimer.current = setTimeout(() => handleFavorite(), 600);
    },
    [handleFavorite, isMobile]
  );

  const cancelHold = useCallback(() => {
    if (pressTimer.current) {
      clearTimeout(pressTimer.current);
    }
  }, []);

  const handleTouchMove = useCallback(
    (e: React.TouchEvent) => {
      //detect scroll and cancel hold event
      if (startY.current !== null) {
        const deltaY = Math.abs(e.touches[0].clientY - startY.current);
        if (deltaY > 10) {
          // Threshold for detecting scroll
          cancelHold();
        }
      }
    },
    [cancelHold]
  );

  const holdHandlers: HoldEventHandlers = {
    onTouchStart: startHold,
    onTouchEnd: cancelHold,
    onTouchCancel: cancelHold,
    onTouchMove: handleTouchMove,
  };

  return {
    holdHandlers,
    isChecked,
    handleFavorite,
  };
};

export default useMobileOnHold;
