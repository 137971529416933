import { SvgIcon, SvgIconProps } from '@mui/material';
import { useExtendedSnackbar } from '../../hooks/useExtendedSnackbar';
import CopyIdIcon from '../icons/CopyIdIcon';
import useLocalization from '../../hooks/useLocalization';
import { formatTicketId } from '../../helpers';

type Props = Omit<SvgIconProps, 'onClick'> & {
  ticketId: string;
};

const CopyTicketId = ({ ticketId, ...props }: Props) => {
  const { enqueueSnackbar } = useExtendedSnackbar();
  const { t } = useLocalization();

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(ticketId);
      enqueueSnackbar(`${t('idSuccessfullyCopied')} - ${formatTicketId(ticketId)}`, {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(t('copyFailed'), {
        variant: 'warning',
      });
    }
  };

  return (
    <SvgIcon
      onClick={handleCopy}
      sx={{
        fontSize: {
          xs: 16,
          md: 20,
        },
        color: 'neutral.200',
        cursor: 'pointer',
        ...props.sx,
      }}
      {...props}
    >
      <CopyIdIcon />
    </SvgIcon>
  );
};

export default CopyTicketId;
