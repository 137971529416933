import { SVGProps } from 'react';

const CornerIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={10} height={18} viewBox='3 0 12 21' fill='none' {...props}>
    <path stroke='#AFAFAF' d='M4 5v15h15' />
    <path stroke='#AFAFAF' d='M12 20a8 8 0 0 0-8-8' />
  </svg>
);

export default CornerIcon;
