import { usePublishedEvents } from '../../../queries';
import { TournamentCarousel } from './TournamentCarousel';
import { groupFeaturedTournaments } from './utils';
import FeaturedCarouselSkeleton from '../skeletons/FeaturedCarouselSkeleton';

const FeaturedTournaments = () => {
  const { data: featuredTournamentEvents, isLoading } = usePublishedEvents({
    queryParams: {
      featuredTournament: true,
      limit: 50,
      count: false,
    },
  });

  if (isLoading) {
    return <FeaturedCarouselSkeleton />;
  }

  if (!featuredTournamentEvents?.items.length) {
    return null;
  }

  const groupedEvents = groupFeaturedTournaments(featuredTournamentEvents.items);

  return (
    <>
      {Object.entries(groupedEvents).map(([tournamentId, events]) => (
        <TournamentCarousel key={tournamentId} events={events} />
      ))}
    </>
  );
};

export default FeaturedTournaments;
