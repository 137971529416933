import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, IconButton, Stack, Toolbar, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { StyleObj } from '../../@types';
import useBreakpoints from '../../hooks/useBreakpoints';
import HeaderTabNavigation from './HeaderTabNavigation';
import AccountMenu from './menus/AccountMenu';
import SearchBar from './SearchBar';
import { SearchProvider } from '../../contexts/SearchContext';
import SearchResults from '../organisms/SearchResults';
import Search from '../atoms/Search';
import Logo from '../icons/Logo';
import { HEADER_MENU_ALLOWED_ROUTES } from '../../config/sidebar';

type HeaderProps = {
  toggleDrawerVisibility?: (isOpen: boolean) => void;
};

export const HEADER_HEIGHT = 64;

const styles: StyleObj = {
  appBar: {
    height: HEADER_HEIGHT,
    backgroundColor: 'primary.600',
  },
  toolbar: {
    minHeight: HEADER_HEIGHT,
    justifyContent: 'space-between',
    px: { xs: 1, md: 2 },
  },
  menuIcon: {
    color: 'info.main',
  },
};

const Header = ({ toggleDrawerVisibility }: HeaderProps) => {
  const { isMobile } = useBreakpoints();
  const location = useLocation();

  const openDrawer = () => {
    toggleDrawerVisibility?.(true);
  };

  return (
    <SearchProvider>
      <AppBar position='fixed' elevation={0} sx={styles.appBar}>
        <Toolbar sx={styles.toolbar}>
          {isMobile ? (
            HEADER_MENU_ALLOWED_ROUTES.some((route) => location.pathname.includes(route)) ? (
              <IconButton onClick={openDrawer}>
                <MenuIcon sx={styles.menuIcon} />
                <Typography component='span' variant='body2' color='neutral.25' ml={0.25}>
                  A-Z
                </Typography>
              </IconButton>
            ) : null
          ) : (
            <Logo />
          )}
          <SearchBar />
          <HeaderTabNavigation />
          <Stack direction='row' alignItems='center' ml='auto'>
            {!isMobile && <Search />}
            <AccountMenu />
          </Stack>
        </Toolbar>
        <SearchResults />
      </AppBar>
    </SearchProvider>
  );
};

export default Header;
