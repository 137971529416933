import { ListItem, ListItemButton, ListItemIcon, Typography } from '@mui/material';
import SportsIcon from '../../atoms/SportsIcon';
import useBreakpoints from '../../../hooks/useBreakpoints';
import { Sport, StyleObj } from '../../../@types';
import { useParams } from 'react-router-dom';

type Props = {
  sport: Sport;
  onClick: (id: string, name: string) => void;
  children?: React.ReactNode;
};

const styles: StyleObj = {
  listItem: {
    p: 0,
    ':hover': {
      backgroundColor: (theme) => theme.palette.neutral[600],
    },
    '& .MuiButtonBase-root': {
      px: {
        xs: 2,
        md: 3,
      },
      py: {
        xs: 1.5,
        md: 2,
      },
    },
  },
  listItemButton: {
    '& .MuiListItemIcon-root': {
      minWidth: '36px',
    },
    alignItems: 'center',
  },
};

const SportListItem = ({ sport, onClick, children }: Props) => {
  const { id, icon, name } = sport;
  const { sportId } = useParams();
  const { isMobile } = useBreakpoints();

  return (
    <ListItem key={id} sx={styles.listItem}>
      <ListItemButton disableRipple sx={styles.listItemButton} onClick={() => onClick(id, name)} disableGutters>
        <ListItemIcon>
          <SportsIcon name={icon} size={isMobile ? 18 : 24} />
        </ListItemIcon>
        <Typography
          noWrap
          title={name}
          sx={{
            typography: {
              xs: sportId === id ? 'h5' : 'body3',
              md: sportId === id ? 'h4' : 'subtitle1',
            },
          }}
        >
          {name}
        </Typography>
        {children}
      </ListItemButton>
    </ListItem>
  );
};

export default SportListItem;
