import { Box, Stack, Typography } from '@mui/material';
import { Market, Outcome, PublishedEventListItem, StyleObj, TopMarketType } from '../../../@types';
import { useBetslip } from '../../../contexts/BetslipContext';
import useLocalization from '../../../hooks/useLocalization';
import MixedMarketOutcome from '../../atoms/MixedMarketOutcome';
import OutcomeCells from '../../molecules/tableElements/OutcomeCells';
import InPlayScoreCell from '../../molecules/tableElements/InPlayScoreCell';
import dayjs from 'dayjs';
import useBreakpoints from '../../../hooks/useBreakpoints';
import { mergeSx } from '../../../utils/styles';
import TotalMarketsLink from '../../atoms/TotalMarketsLink';
import EventDetailsCell from '../../molecules/tableElements/EventDetailsCell';
import TableDivider from '../../atoms/TableDivider';
import UpcomingStatsButton from '../../atoms/UpcomingStatsButton';
import MiniStatsWidget from '../../molecules/tableElements/MiniStatsWidget';

type EventsTableProps = {
  data: PublishedEventListItem[];
  outcomeTypes?: Outcome[];
  showTableHead?: boolean;
  firstCellHeader?: string;
  inPlay?: boolean;
  topMarketTypes?: TopMarketType[];
};

const styles: StyleObj = {
  container: (theme) => ({
    width: '100%',
    backgroundColor: theme.palette.neutral[700],
    border: 'none',
    boxShadow: 'none',
    borderRadius: 0,
  }),
  header: {
    backgroundColor: 'neutral.600',
    py: 0.5,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  headerItem: {
    p: {
      xs: 0.5,
      md: 0.75,
    },
    '& .MuiTypography-root': {
      typography: {
        xs: 'body4',
        lg: 'body2',
      },
      fontWeight: {
        xs: 600,
        lg: 600,
      },
      color: 'text.primary',
    },
  },
  dateHeader: {
    backgroundColor: 'neutral.800',
    py: {
      xs: 0.5,
      lg: 1,
    },
    px: {
      xs: 1,
      lg: 2,
    },
    width: '100%',
  },
  firstHeaderCell: {
    width: { xs: '50%', lg: '42.5%' },
    px: {
      xs: 1,
      lg: 2,
    },
  },
  totalMarketsHeaderCell: {
    width: '7.5%',
    textAlign: 'center',
  },
  outcomeHeadersContainer: {
    display: 'flex',
    width: '50%',
    justifyContent: 'space-between',
  },
  outcomeHeaderCell: {
    textAlign: 'center',
    flex: 1,
    minWidth: 0,
    typography: {
      xs: 'body4',
      lg: 'body3',
    },
  },
  rows: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    borderBottom: (theme) => `1px solid ${theme.palette.neutral[600]}`,
  },
  detailsWrapper: {
    width: { xs: '50%', lg: '42.5%' },
    display: 'flex',
    p: {
      xs: 1,
      lg: 2,
    },
  },
  totalMarketsCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    px: 1,
    width: '7.5%',
  },
  outcomesContainer: {
    display: 'flex',
    width: '50%',
  },
  scores: {
    gap: {
      xs: 0.5,
      lg: 1,
    },
    alignItems: 'end',
  },
  statsButton: {
    justifyContent: 'center',
  },
  timeDisplay: {
    minWidth: '63px',
    '&.MuiTypography-root': {
      lineHeight: '20px',
    },
    color: 'neutral.300',
    typography: {
      xs: 'body4',
      lg: 'body3',
    },
  },
};

const EventsTable = ({
  data,
  outcomeTypes,
  showTableHead,
  firstCellHeader,
  inPlay,
  topMarketTypes,
}: EventsTableProps) => {
  const { addOrRemoveBet } = useBetslip();
  const { getLocalizedOutcomeName, getLocalizedMarketName } = useLocalization();
  const { isTablet } = useBreakpoints();

  const addToBetslip = (event: PublishedEventListItem, topMarket: Market, outcome: Outcome) => {
    if (!topMarket) return;

    addOrRemoveBet({
      outcomeId: outcome.id,
      odds: outcome.odds,
      eventName: event.name,
      marketId: topMarket.id,
      marketName: topMarket.name,
      marketShortName: topMarket.shortName,
      outcomeName: outcome.name,
      outcomeShortName: outcome.shortName,
      eventId: event.id,
      specialValues: topMarket.specialValues,
      marketType: topMarket.marketType,
      isLive: event.isLive,
      manualControl: event.manualControl || topMarket.manualControl,
    });
  };

  const isMixedMarketGroup = (topMarketTypes?.length ?? 0) > 1;
  const headerCells = isMixedMarketGroup ? topMarketTypes : outcomeTypes;
  return (
    <Box sx={styles.container}>
      {showTableHead && (
        <Box sx={styles.header}>
          <Box sx={mergeSx(styles.headerItem, styles.firstHeaderCell)}>
            <Typography fontWeight={600}>{firstCellHeader}</Typography>
          </Box>
          <Box sx={styles.outcomeHeadersContainer}>
            {headerCells?.map((headerField) => (
              <Box key={headerField.id} sx={mergeSx(styles.headerItem, styles.outcomeHeaderCell)}>
                <Typography fontWeight={700} maxWidth='100%' noWrap>
                  {headerField.shortName ??
                    (isMixedMarketGroup
                      ? getLocalizedMarketName(`${headerField.name}, ${(headerField as TopMarketType).eventPartName}`)
                      : getLocalizedOutcomeName(headerField.name))}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      )}
      <Stack sx={styles.rows}>
        {data.map((row) => (
          <Box key={row.id} sx={styles.row}>
            <Stack direction='row' justifyContent='space-between' sx={styles.detailsWrapper}>
              <Stack direction='row' alignItems='start' sx={{ width: '85%', gap: 2 }}>
                {!inPlay && !isTablet && (
                  <Typography sx={styles.timeDisplay}>{dayjs(row.startDate).format('ddd HH:mm')}</Typography>
                )}
                <EventDetailsCell event={row} inPlay={inPlay} />
              </Stack>
              {inPlay ? (
                <Stack sx={styles.scores}>
                  <InPlayScoreCell score={row.mainEventPartScore?.valueHome} />
                  <InPlayScoreCell score={row.mainEventPartScore?.valueAway} />
                  {row.tournament.sport.id === '1' && <MiniStatsWidget eventId={row.id} />}
                </Stack>
              ) : (
                <Stack sx={mergeSx(styles.scores, styles.statsButton)}>
                  <UpcomingStatsButton eventId={row.statscoreId} />
                </Stack>
              )}
            </Stack>

            <Box sx={styles.outcomesContainer}>
              <TableDivider />
              {isMixedMarketGroup ? (
                <MixedMarketOutcome topMarketTypes={topMarketTypes} row={row} addToBetslip={addToBetslip} />
              ) : (
                <OutcomeCells event={row} outcomeTypes={outcomeTypes as Outcome[]} addToBetslip={addToBetslip} />
              )}
              <TableDivider />
            </Box>
            {!isTablet && (
              <Box sx={styles.totalMarketsCell}>
                <TotalMarketsLink eventId={row.id} totalMarkets={row.totalMarkets} inPlay={inPlay} />
              </Box>
            )}
          </Box>
        ))}
      </Stack>
    </Box>
  );
};

export default EventsTable;
