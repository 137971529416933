import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import { Badge, Box, IconButton } from '@mui/material';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { BetslipWithBetsExtendedInfo, StyleObj } from '../../@types';
import { BETSLIP_WIDTH, CURRENCY, QUERY_KEYS } from '../../constants';
import { useBetslip } from '../../contexts/BetslipContext';
import { checkIsWaysEventForReofferedBetslip, getLocalizedBettingTypeFromHitCount } from '../../helpers';
import { useInvalidateQuery } from '../../hooks/useInvalidateQuery';
import { useCountPendingBetslips } from '../../queries';
import { getReofferedColor } from '../../utils';
import { getData, patchData } from '../../utils/api';
import ReofferedBetslipCountdown from '../atoms/ReofferedBetslipCountdown';
import ReofferedBetslipItem from '../molecules/ReofferedBetslipItem';
import useLocalization from '../../hooks/useLocalization';
import OddsDisplay from '../atoms/OddsDisplay';

const styles: StyleObj = {
  drawer: {
    zIndex: 1400,
    textAlign: 'center',
    '& .MuiPaper-root': {
      width: {
        xs: '100%',
        sm: BETSLIP_WIDTH,
      },
      background: (theme) => theme.palette.neutral[50],
      borderRadius: {
        xs: 0,
        sm: '4px 4px 0 0',
      },
      margin: 'auto',
      mb: {
        xs: 0,
        md: 4,
      },
    },
    '& form': { maxHeight: '95vh' },
  },
  betStack: {
    flexGrow: 1,
    overflowY: 'auto',
    maxHeight: 566,
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      width: 14,
      borderColor: 'neutral.50',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      backgroundColor: 'neutral.300',
    },
  },
  bettingSlipCountSection: {
    p: 1.5,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'primary.50',
    color: 'neutral.700',
    fontWeight: 'bold',
  },
  timerWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  bettingSlipNavigationWrapper: {
    display: 'flex',
  },
  navigationBtn: {
    p: 0,
  },
  bettingSlipCountBadge: {
    mr: 1,
    '& .MuiBadge-badge': {
      position: 'relative',
      transform: 'none',
      WebkitTransform: 'none',
    },
  },
  actionBtn: {
    borderRadius: 0,
    width: '50%',
    color: 'white',
    py: 1.5,
  },
  bankerOrNormalText: {
    px: 1.5,
    py: 1,
    color: 'primary.600',
    textAlign: 'right',
  },
  betslipInfo: {
    background: (theme) => theme.palette.neutral[100],
    borderBottom: '1px solid #D9D9D9',
    py: 1,
    pl: 1.5,
    height: 40,
  },
  betslipAmountField: {
    height: 42,
    flexDirection: 'row',
    background: 'white',
    ml: 1.5,
    mt: 0.25,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    px: 2,
    gap: 0.5,
    borderBottom: '1px solid',
    borderColor: 'primary.main',
    '& h5': {
      fontFamily: 'Open Sans',
    },
  },
  previousValue: {
    textDecoration: 'line-through',
    color: 'neutral.800',
    opacity: 0.5,
  },
};

const ReofferedBetslipDrawer = () => {
  const [open, setOpen] = useState(false);
  const [currentBetslip, setCurrentBetslip] = useState<string>('');

  const { reofferedBettingSlipsIds, removeReofferedBettingSlip } = useBetslip();
  const { refetch: refetchPendingBetslipsCount } = useCountPendingBetslips();

  const { enqueueSnackbar } = useSnackbar();
  const invalidateData = useInvalidateQuery();
  const { t } = useLocalization();

  useEffect(() => {
    setOpen(reofferedBettingSlipsIds.length > 0);
    if (!currentBetslip || !reofferedBettingSlipsIds.includes(currentBetslip)) {
      setCurrentBetslip(reofferedBettingSlipsIds[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reofferedBettingSlipsIds]);

  const { data: betslip } = useQuery({
    queryKey: [QUERY_KEYS.betslip, currentBetslip],
    queryFn: (): Promise<BetslipWithBetsExtendedInfo> => getData(`betting-slips/my-bets/${currentBetslip}`),
    enabled: !!currentBetslip,
  });

  const { data: reofferedFromBetslip } = useQuery({
    queryKey: [QUERY_KEYS.betslip, betslip?.reofferedFromId],
    queryFn: (): Promise<BetslipWithBetsExtendedInfo> => getData(`betting-slips/my-bets/${betslip?.reofferedFromId}`),
    enabled: !!betslip?.reofferedFromId,
  });

  const bets = betslip?.bets || [];
  const isBanker = bets.some((bet) => bet.banker);

  const acceptOffer = useMutation({
    mutationFn: (bettingSlipId: string) =>
      patchData(`/betting-slips/my-bets/${bettingSlipId}`, {
        accept: true,
      }),
    onSuccess: () => {
      invalidateData([QUERY_KEYS.betslip, currentBetslip]);
      enqueueSnackbar(t('confirmed'), {
        variant: 'success',
      });
      removeReofferedBettingSlip(currentBetslip);
      refetchPendingBetslipsCount();
    },
  });

  const rejectOffer = useMutation({
    mutationFn: (bettingSlipId: string) =>
      patchData(`/betting-slips/my-bets/${bettingSlipId}`, {
        accept: false,
      }),
    onSuccess: () => {
      enqueueSnackbar(t('bettingSlipRejected'), {
        variant: 'warning',
      });
      removeReofferedBettingSlip(currentBetslip);
      refetchPendingBetslipsCount();
    },
  });

  const betslipOdds = betslip?.totalOdds;
  const reofferedBetslipOdds = reofferedFromBetslip?.totalOdds;

  const { color: totalOddsColor, changed: totalOddsChanged } = getReofferedColor(betslipOdds, reofferedBetslipOdds);
  const { color: stakeAmountColor, changed: stakeAmountChanged } = getReofferedColor(
    betslip?.stakeAmountPerCombination,
    reofferedFromBetslip?.stakeAmountPerCombination
  );
  const { color: maxPayoutColor, changed: maxPayoutChanged } = getReofferedColor(
    betslip?.maxPayout,
    reofferedFromBetslip?.maxPayout
  );

  const localizedBettingType = getLocalizedBettingTypeFromHitCount(t, betslip?.requiredHitCount || 0);

  return (
    <>
      <Drawer anchor='bottom' elevation={0} open={open} sx={styles.drawer}>
        <Box sx={styles.bettingSlipCountSection}>
          <Badge badgeContent={reofferedBettingSlipsIds.length} color='primary' sx={styles.bettingSlipCountBadge} />
          <Box sx={styles.timerWrapper}>
            {t('betslipReoffered')}
            {betslip?.createdAt && (
              <ReofferedBetslipCountdown createdAt={betslip?.createdAt} ticketId={currentBetslip} />
            )}
            <Box sx={styles.bettingSlipNavigationWrapper}>
              <IconButton
                disabled={
                  reofferedBettingSlipsIds.indexOf(currentBetslip) === 0 || reofferedBettingSlipsIds.length === 1
                }
                sx={styles.navigationBtn}
                onClick={() => {
                  const nextIndex =
                    (reofferedBettingSlipsIds.indexOf(currentBetslip) - 1 + reofferedBettingSlipsIds.length) %
                    reofferedBettingSlipsIds.length;
                  setCurrentBetslip(reofferedBettingSlipsIds[nextIndex]);
                }}
              >
                <NavigateBefore />
              </IconButton>

              <Typography fontWeight={700}>
                {reofferedBettingSlipsIds.indexOf(currentBetslip) + 1}/{reofferedBettingSlipsIds.length}
              </Typography>
              <IconButton
                sx={styles.navigationBtn}
                onClick={() => {
                  const nextIndex =
                    (reofferedBettingSlipsIds.indexOf(currentBetslip) + 1) % reofferedBettingSlipsIds.length;
                  setCurrentBetslip(reofferedBettingSlipsIds[nextIndex]);
                }}
              >
                <NavigateNext />
              </IconButton>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Typography sx={styles.bankerOrNormalText} variant='h6'>
          {isBanker ? t('banker') : t('normal')}
        </Typography>
        <Divider />
        <Stack sx={styles.betStack}>
          {reofferedFromBetslip &&
            bets.map((bet, index) => (
              <ReofferedBetslipItem
                key={bet.outcome.id}
                bet={bet}
                previousBet={reofferedFromBetslip.bets[index]}
                showBankers={isBanker}
                isWaysEvent={checkIsWaysEventForReofferedBetslip(bet, bets)}
                bets={bets}
              />
            ))}
        </Stack>
        <Divider />
        {betslip && (
          <>
            {betslip?.bettingSlipType !== 'Single' && (
              <Typography variant='body3' color='neutral.600' pl={1.5} pr={0.25} py={1} textAlign='left'>
                {betslip?.bettingSlipType === 'Multiple' ? t('multiple') : t('system')}
              </Typography>
            )}
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='space-between'
              flexGrow={1}
              sx={styles.betslipInfo}
            >
              <Stack spacing={0.5} direction='row' alignItems='center'>
                <Typography variant='h5' color='neutral.600'>
                  {localizedBettingType}
                </Typography>
                {totalOddsChanged && (
                  <OddsDisplay
                    value={reofferedBetslipOdds ?? ''}
                    variant='h5'
                    color='neutral.600'
                    sx={styles.previousValue}
                  />
                )}
                <OddsDisplay value={betslipOdds ?? ''} variant='h5' color={totalOddsColor} />
              </Stack>
              <Stack direction='row' alignItems='center'>
                <Typography variant='body2' color='neutral.600'>
                  {betslip?.combinationsCount}x
                </Typography>
                <Box sx={styles.betslipAmountField}>
                  {stakeAmountChanged && (
                    <Typography variant='h5' sx={styles.previousValue}>
                      {reofferedFromBetslip?.stakeAmountPerCombination?.toFixed(2)}
                    </Typography>
                  )}
                  <Typography variant='h5' color={stakeAmountColor} textAlign='right' fontWeight={600}>
                    {betslip?.stakeAmountPerCombination?.toFixed(2)}
                  </Typography>
                </Box>
              </Stack>
            </Stack>
          </>
        )}
        <Divider />
        <Typography variant='body3' color='neutral.600' px={1.5} py={1} textAlign='right'>
          {t('maxPossibleWinning')}:
          {maxPayoutChanged && (
            <Typography component='span' variant='body3' sx={styles.previousValue} px={0.5}>
              {CURRENCY.symbol}
              {reofferedFromBetslip?.maxPayout.toFixed(2)}
            </Typography>
          )}
          <Typography component='span' variant='body3' color={maxPayoutColor}>
            {CURRENCY.symbol}
            {betslip?.maxPayout.toFixed(2)}
          </Typography>
        </Typography>
        <Box>
          <Button onClick={() => acceptOffer.mutate(currentBetslip)} variant='contained' sx={styles.actionBtn}>
            {t('accept')}
          </Button>
          <Button
            onClick={() => rejectOffer.mutate(currentBetslip)}
            variant='contained'
            sx={{
              ...styles.actionBtn,
              backgroundColor: 'error.600',
              ':hover': {
                backgroundColor: 'error.700',
              },
            }}
          >
            {t('reject')}
          </Button>
        </Box>
      </Drawer>
    </>
  );
};

export default ReofferedBetslipDrawer;
