import { useState, useEffect, useRef } from 'react';
import { PublishedEventListItem, SportsType } from '../@types';
import { SECOND } from '../constants';

export const SCORE_CHANGE_INDICATOR_DURATION = 6 * SECOND;
const SCORE_CHANGE_INDICATOR_SUPPORTED_SPORTS: SportsType[] = [
  'soccer',
  'water_polo',
  'ice_hockey',
  'futsal',
  'floorball',
];

const useScoreChange = (event: PublishedEventListItem, side: 'home' | 'away') => {
  const currentScore = side === 'home' ? event.mainEventPartScore?.valueHome : event.mainEventPartScore?.valueAway;

  const [scoreChanged, setScoreChanged] = useState(false);
  const previousScoreRef = useRef<number | null | undefined>(currentScore);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const isSupportedSport = SCORE_CHANGE_INDICATOR_SUPPORTED_SPORTS.includes(event.tournament.sport.icon);
  const hasScoreIncreased =
    currentScore != null && previousScoreRef.current != null && currentScore > previousScoreRef.current;
  const triggerScoreChangeIndicator = isSupportedSport && hasScoreIncreased;

  useEffect(() => {
    if (triggerScoreChangeIndicator) {
      setScoreChanged(true);

      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }

      timerRef.current = setTimeout(() => {
        setScoreChanged(false);
      }, SCORE_CHANGE_INDICATOR_DURATION);
    }
  }, [triggerScoreChangeIndicator]);

  useEffect(() => {
    previousScoreRef.current = currentScore;
  }, [currentScore]);

  return { scoreChanged };
};

export default useScoreChange;
