import { Sport } from '../../@types';
import { DEFAULT_UPCOMING_MATCHES_INTERVAL } from '../../constants';
import Heading from '../atoms/Heading';
import { Stack } from '@mui/material';
import StyledSkeleton from '../atoms/StyledSkeleton';
import useLocalization from '../../hooks/useLocalization';

const FeaturedSport = ({ featuredSport }: { featuredSport?: Sport }) => {
  const { t } = useLocalization();

  if (!featuredSport)
    return (
      <Stack spacing={{ xs: 0.5, md: 1 }} width={'100%'} p={{ xs: 0.5, md: 1 }}>
        <StyledSkeleton sx={{ height: { xs: 51, md: 80 } }} />
        <StyledSkeleton sx={{ height: { xs: 51, md: 80 } }} />
      </Stack>
    );

  return (
    <>
      <Heading direction='column' alignItems='start' spacing={0.5} to={`/sports/${featuredSport?.id}`}>
        <Heading.Subtitle>{featuredSport.name}</Heading.Subtitle>
        <Heading.Link>{t('goToMatches')}</Heading.Link>
      </Heading>
      <Heading
        direction='column'
        alignItems='start'
        spacing={0.5}
        to={`/sports/${featuredSport.id}/upcoming-matches/all-matches`}
      >
        <Heading.Subtitle>{featuredSport.name}</Heading.Subtitle>
        <Heading.Link>{t('goToUpcomingMatches', { count: DEFAULT_UPCOMING_MATCHES_INTERVAL })}</Heading.Link>
      </Heading>
    </>
  );
};

export default FeaturedSport;
