import { isEmpty } from 'lodash-es';
import { Market } from '../@types';
import { MessageType } from '../hooks/useWebsocket';

export type MessageBetType = {
  odds?: string;
  id: string;
  isActive?: boolean;
  isAvailable?: boolean;
};

const topMarketsUpdater = (prev: Market[] | null, next: MessageType<MessageBetType>): Market[] | null => {
  if (!prev || !next) {
    return prev;
  }

  const { event, payload } = next;

  if (isEmpty(event) || isEmpty(payload)) {
    return prev;
  }

  const updatedMarkets = prev.map((market) => {
    if (next.event === 'OUTCOME') {
      if (market.manualControl) return market;

      const outcomeOddsUpdated = 'odds' in payload;
      const outcomeStatusUpdated = 'isActive' in payload;
      const outcomeAvalabilityUpdated = 'isAvailable' in payload;

      const outcomeIndex = market.outcomes.findIndex((o) => o.id === payload.id);

      if (outcomeIndex !== undefined && outcomeIndex !== -1 && market) {
        if (outcomeOddsUpdated && payload.odds) {
          market.outcomes[outcomeIndex].odds = payload.odds;
        }
        if (outcomeStatusUpdated) {
          market.outcomes[outcomeIndex].isActive = payload.isActive || false;
        }
        if (outcomeAvalabilityUpdated) {
          market.outcomes[outcomeIndex].isAvailable = payload.isAvailable || false;
        }
      }
    }
    if (next.event === 'MARKET') {
      if (market && 'isActive' in next.payload) {
        market.isActive = next.payload.isActive || false;
      }
    }
    return { ...market };
  });

  return updatedMarkets;
};

export default topMarketsUpdater;
