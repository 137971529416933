import { Box, IconButton } from '@mui/material';
import { useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import { StyleObj } from '../../@types';
import { useSearchContext } from '../../contexts/SearchContext';
import useBreakpoints from '../../hooks/useBreakpoints';
import Logo from './Logo';

const styles: StyleObj = {
  logo: {
    borderRadius: 2,
  },
  container: {
    transition: 'transform 0.3s ease-out',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

const MAX_SWIPE_OFFSET = 25;

const SwipeableLogo = ({ size = 'medium' }: { size?: 'small' | 'medium' }) => {
  const [swipeOffset, setSwipeOffset] = useState(0);

  const { setShowSearchBar } = useSearchContext();
  const { isMobile } = useBreakpoints();

  const handleLogoSwipeEnd = () => {
    setShowSearchBar(isMobile);
    setSwipeOffset(0);
  };

  const handlers = useSwipeable({
    onSwiping: (event) => {
      if (isMobile) {
        const newOffset = Math.min(Math.abs(event.deltaX), MAX_SWIPE_OFFSET);
        setSwipeOffset(event.dir === 'Left' ? -newOffset : newOffset);
      }
    },
    onSwiped: handleLogoSwipeEnd,
    trackMouse: true,
  });

  return (
    <IconButton sx={styles.logo} {...handlers} disableTouchRipple>
      <Box
        sx={{
          ...styles.container,
          transform: `translateX(${swipeOffset}px)`,
        }}
      >
        <Logo size={size} />
      </Box>
    </IconButton>
  );
};

export default SwipeableLogo;
