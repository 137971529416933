import { TFunction } from 'i18next';
import {
  playerPatterns,
  teamPatterns,
  intervalPatterns,
  goalXPatterns,
  scorePatterns,
  noBetPatterns,
  gamesPatterns,
  firstPatterns,
  racePatterns,
  cornerPatterns,
} from './marketNamePatterns';
export const DEFAULT_LANGUAGE = 'en';

export const outcomeCache = new Map<string, string>();
export const marketNameCache = new Map<string, Map<string, string>>();

export const getMarketCachedValue = (locale: string, key: string): string | undefined => {
  return marketNameCache.get(locale)?.get(key);
};

export const setMarketCachedValue = (locale: string, key: string, translation: string): void => {
  if (!marketNameCache.has(locale)) {
    marketNameCache.set(locale, new Map());
  }
  const localeCache = marketNameCache.get(locale);
  localeCache?.set(key, translation);
};

export const DYNAMIC_TRANSLATIONS_CONFIG = {
  Player: { patterns: playerPatterns, params: ['player'] },
  Team: { patterns: teamPatterns, params: ['team', 'goal', 'minute'] },
  Interval: { patterns: intervalPatterns, params: ['param1', 'interval'] },
  Goal: { patterns: goalXPatterns, params: ['goal', 'minute'] },
  Score: { patterns: scorePatterns, params: ['goals'] },
  Bet: { patterns: noBetPatterns, params: ['team'] },
  Games: { patterns: gamesPatterns, params: ['gameX', 'gameY'] },
  Race: { patterns: racePatterns, params: ['param1', 'param2'] },
  First: { patterns: firstPatterns, params: ['param1', 'param2', 'param3'] },
  Corner: { patterns: cornerPatterns, params: ['corner', 'interval'] },
};