import { MenuItemType } from '../@types';

export const ODDS_FORMAT = {
  DECIMAL: 'DECIMAL',
  FRACTIONAL: 'FRACTIONAL',
  AMERICAN: 'AMERICAN',
} as const;

export const ODDS_FORMAT_OPTIONS: MenuItemType[] = Object.entries(ODDS_FORMAT).map(([key, value]) => ({
  key,
  label: value.toLocaleLowerCase(),
}));
