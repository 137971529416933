import { Collapse, Divider, Stack, Typography } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { BetslipWithBetsExtendedInfo, StyleObj } from '../../@types';
import { CURRENCY, QUERY_KEYS } from '../../constants';
import { getStake, getSystemBettingType } from '../../helpers';
import { formatScore } from '../../utils';
import { getData } from '../../utils/api';
import { BetDetails } from '../atoms/BetDetails';
import ResolveStatusIcon from '../icons/ResolveStatusIcon';
import CopyBetslip from './CopyBetslip';
import useLocalization from '../../hooks/useLocalization';
import useOddsFormat from '../../hooks/useOddsFormat';
import OddsDisplay from '../atoms/OddsDisplay';
import CopyTicketId from '../atoms/CopyTicketId';

type Props = {
  open: boolean;
  betslipId: string;
};

const makeStyles = (isOpen: boolean): StyleObj => ({
  collapsedContainer: (theme) => ({
    '&.MuiTableCell-body': {
      padding: '4px 0px',
      border: isOpen ? `1px solid ${theme.palette.neutral[400]}` : '1px solid transparent',
      background: isOpen ? `${theme.palette.neutral[400]}` : 'transparent',
    },
    '& p': {
      maxWidth: {
        xs: window.innerWidth / 3,
        md: 'initial',
      },
    },
    '& .MuiTypography-caption': {
      marginLeft: 'auto',
      fontSize: 10,
    },
    '& .MuiTypography-body3': {
      fontSize: 12,
    },
  }),
  collapsedRow: (theme) => ({
    borderBottom: `1px solid ${theme.palette.neutral[500]}`,
    padding: '12px 16px',
    '&:last-child': {
      borderBottom: 'none',
    },
  }),
  liveIndicator: {
    paddingInline: '4px',
    backgroundColor: 'neutral.600',
    borderRadius: '2px',
  },
  jackpot: {
    flexDirection: 'row',
    justifyContent: 'end',
    alignItems: 'center',
    gap: 1,
    px: 2,
    pt: 1.5,
    pb: 1,
  },
});

const BetHistoryCollapsibleCell = ({ open, betslipId }: Props) => {
  const { t } = useLocalization();
  const { selectedFormat } = useOddsFormat();

  const { data: betslip } = useQuery({
    queryKey: [QUERY_KEYS.betHistory, betslipId],
    queryFn: (): Promise<BetslipWithBetsExtendedInfo> => getData(`betting-slips/my-bets/${betslipId}`),
    enabled: !!betslipId && open,
  });

  const styles = makeStyles(!!betslip && open);

  return (
    <TableCell colSpan={5} sx={styles.collapsedContainer}>
      <Collapse in={!!betslip && open} timeout='auto' unmountOnExit>
        {betslip && (
          <Stack>
            <Stack
              justifyContent='space-between'
              alignItems='center'
              spacing={1}
              direction='row'
              sx={styles.collapsedRow}
            >
              <Stack direction='row' spacing={1} flex={1}>
                <Typography variant='body2'>{t('stake')}</Typography>
                <Typography variant='body2' fontWeight={600}>
                  {getStake(selectedFormat, betslip)}
                  {getSystemBettingType(betslip.requiredHitCount, betslip.bettingSlipType, t)}
                </Typography>
              </Stack>
              <CopyTicketId ticketId={betslip.ticketId} />
              <CopyBetslip betslipId={betslip.id} from={betslip.from} />
            </Stack>
            {betslip.bets?.map((bet) => (
              <Stack key={bet?.id} sx={styles.collapsedRow} spacing={1}>
                <Stack justifyContent='space-between' alignItems='center' direction='row'>
                  <Typography variant='body2' fontWeight={600} flex={4}>
                    {bet?.event?.name}
                  </Typography>
                  <Typography variant='body2' fontWeight={600} flex={3} textAlign='center'>
                    {formatScore(bet.mainEventPartScore)}
                  </Typography>
                  <OddsDisplay value={bet?.odds} variant='body2' flex={3} textAlign='right' />
                </Stack>
                <Typography variant='caption' sx={styles.liveIndicator}>
                  {bet?.isLive ? t('live') : t('preMatch')}
                </Typography>
                <Stack justifyContent='space-between' alignItems='center' direction='row'>
                  <Stack alignItems='end' direction='row' spacing={1}>
                    <ResolveStatusIcon status={bet?.status} id={bet.id} />
                    <BetDetails.Outcome outcomeName={bet?.outcome?.name} outcomeShortName={bet?.outcome?.shortName} />
                    {!!bet.outcome.shortName && (
                      <BetDetails.SpecialValues
                        outcomeName={bet.outcome.name}
                        specialValues={bet.specialValues}
                        marketTypeName={bet.market.marketType.name}
                        sportId={bet.market.marketType.sportId}
                      />
                    )}
                    <BetDetails.Market market={bet.market} />
                  </Stack>
                  <Typography variant='body3'>{dayjs(bet?.event?.startDate).format('DD/MM HH:mm')}</Typography>
                </Stack>
              </Stack>
            ))}
          </Stack>
        )}
        {!!betslip?.jackpot && (
          <>
            <Divider />
            <Stack sx={styles.jackpot}>
              <Typography fontWeight={600}>Winning Jackpot: </Typography>
              <Typography color='secondary' fontWeight={700}>
                {CURRENCY.symbol}
                {betslip?.jackpot.amount.toFixed(2)}
              </Typography>
              <Typography>({betslip?.jackpot.name})</Typography>
            </Stack>
          </>
        )}
      </Collapse>
    </TableCell>
  );
};

export default BetHistoryCollapsibleCell;
