import { Market, Outcome, PublishedEventListItem, StyleObj, TopMarketType } from '../../@types';
import { Box, Typography } from '@mui/material';
import OutcomeTableCell from './OutcomeTableCell';

const styles: StyleObj = {
  container: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  marketTypeContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,

    minWidth: 0,
    minHeight: 100,
  },
  emptyOutcomeCell: {
    opacity: 0.3,
    cursor: 'not-allowed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& span': {
      typography: {
        xs: 'body4',
        md: 'body2',
      },
    },
    p: {
      xs: 1,
      md: 2,
    },
    height: '50%',
  },
};

interface MixedMarketOutcomeProps {
  topMarketTypes?: TopMarketType[];
  row: PublishedEventListItem;
  addToBetslip: (event: PublishedEventListItem, topMarket: Market, outcome: Outcome) => void;
}

const MixedMarketOutcome = ({ topMarketTypes, row, addToBetslip }: MixedMarketOutcomeProps) => {
  return (
    <Box sx={styles.container}>
      {topMarketTypes?.map((topMarketType) => {
        const currentTopMarket = row?.topMarkets?.find((item) => item.marketTypeId === topMarketType.id);
        return (
          <Box key={topMarketType.id} sx={styles.marketTypeContainer}>
            {topMarketType.outcomeTypes.map((outcomeType) => {
              const outcome = currentTopMarket?.outcomes?.find((item) => item.outcomeTypeId === outcomeType.id);
              if (!outcome || !currentTopMarket) {
                return (
                  <Box key={outcomeType.id} sx={styles.emptyOutcomeCell}>
                    <Typography textAlign='center' component='span'>
                      -
                    </Typography>
                  </Box>
                );
              }

              return (
                <OutcomeTableCell
                  onClick={() => addToBetslip(row, currentTopMarket, outcome)}
                  key={outcome.id}
                  sportId={currentTopMarket?.marketType?.sportId}
                  outcome={outcome}
                  disabled={!row.isActive || !row.topMarkets[0]?.isActive || !outcome.isActive}
                  isMarketSingleDisplayType={row.topMarkets[0]?.isSingleDisplayType ?? false}
                  specialValues={currentTopMarket?.specialValues}
                  marketTypeName={currentTopMarket?.marketType?.name}
                  mixedMarket
                />
              );
            })}
          </Box>
        );
      })}
    </Box>
  );
};

export default MixedMarketOutcome;
