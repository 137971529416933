import { Stack } from '@mui/material';
import StyledSkeleton from '../../atoms/StyledSkeleton';
import SkeletonContainer from '../../atoms/SkeletonContainer';

const TopListSkeleton = ({ itemsNumber = 5 }: { itemsNumber?: number }) => {
  return (
    <SkeletonContainer>
      <Stack width={'100%'} p={1} spacing={{ xs: 0.5, md: 1 }}>
        <StyledSkeleton sx={{ height: { xs: 28, md: 50 } }} />
        {Array.from({ length: itemsNumber }).map((_, index) => (
          <StyledSkeleton key={index} sx={{ height: { xs: 24, md: 32 } }} />
        ))}
      </Stack>
    </SkeletonContainer>
  );
};

export default TopListSkeleton;
