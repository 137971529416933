import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import { SanityImage } from '../../@types';
import { getSanityImage } from '../../helpers/sanity';
import { useSanity } from '../../hooks/useSanity';
import { useNavigate } from 'react-router-dom';

type LogoProps = {
  size?: 'small' | 'medium';
  isClickable?: boolean;
};

const setFavicon = (url: string) => {
  let link = document.getElementById('dynamic-favicon') as HTMLLinkElement;
  if (!link) {
    link = document.createElement('link');
    link.rel = 'icon';
    document.head.appendChild(link);
  }
  link.href = url;
};

const Logo = ({ size = 'medium', isClickable = true }: LogoProps) => {
  const { data: logo } = useSanity<{ image: SanityImage }>('Logo');
  const sanitySrc = getSanityImage(logo?.[0]?.image);
  const storedSrc = localStorage.getItem('logoSrc') ?? '';
  const navigate = useNavigate();

  const src = sanitySrc || storedSrc;

  const handleLogoClick = () => {
    if (!isClickable) return;

    navigate('/');
  };

  useEffect(() => {
    setFavicon(src);
    if (sanitySrc) {
      localStorage.setItem('logoSrc', src);
    }
  }, [sanitySrc, src]);

  return src ? (
    <Box
      component='img'
      src={src}
      height={size === 'medium' ? '54px' : '32px'}
      alt='Logo'
      onClick={handleLogoClick}
      sx={{ cursor: isClickable ? 'pointer' : 'auto' }}
    />
  ) : (
    <Typography variant={size === 'small' ? 'h3' : 'h1'} color='secondary'>
      LOGO
    </Typography>
  );
};

export default Logo;
