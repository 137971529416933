import { Divider, DividerProps } from '@mui/material';

const TableDivider = ({ sx, ...rest }: DividerProps) => {
  return (
    <Divider
      orientation='vertical'
      flexItem
      sx={{
        borderColor: 'neutral.600',
        alignSelf: 'center',
        height: '66.6%',
        ...sx,
      }}
      {...rest}
    />
  );
};

export default TableDivider;
