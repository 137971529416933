import { Box, Typography } from '@mui/material';
import { PublishedEventListItem, Outcome, StyleObj, Market } from '../../../@types';
import OutcomeTableCell from '../../atoms/OutcomeTableCell';
import useBreakpoints from '../../../hooks/useBreakpoints';

const styles: StyleObj = {
  container: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'space-between',
  },
  emptyOutcomeCell: {
    borderBottom: '1px solid',
    borderColor: 'neutral.600',
    opacity: 0.3,
    cursor: 'not-allowed',
    backgroundColor: 'transparent',
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    py: {
      xs: 2,
      md: 2,
    },
    px: {
      xs: 1,
      md: 2,
    },
  },
};

const OutcomeCells = ({
  event,
  outcomeTypes,
  addToBetslip,
}: {
  event: PublishedEventListItem;
  outcomeTypes: Outcome[];
  addToBetslip: (event: PublishedEventListItem, topMarket: Market, outcome: Outcome) => void;
}) => {
  const { isMobile } = useBreakpoints();

  return (
    <Box sx={styles.container}>
      {outcomeTypes?.map((outcomeType) => {
        const outcome = event?.topMarkets?.[0]?.outcomes?.find((item) => item.outcomeTypeId === outcomeType.id);
        if (!outcome) {
          return (
            <Box key={outcomeType.id} sx={styles.emptyOutcomeCell}>
              <Typography textAlign='center'>-</Typography>
            </Box>
          );
        }
        return (
          <OutcomeTableCell
            key={outcome.id}
            outcome={outcome}
            sportId={event.topMarkets[0]?.marketType?.sportId}
            onClick={() => addToBetslip(event, event.topMarkets[0], outcome)}
            disabled={!event.isActive || !event.topMarkets[0]?.isActive || !outcome.isActive}
            isMarketSingleDisplayType={event.topMarkets[0]?.isSingleDisplayType ?? false}
            specialValues={event.topMarkets[0]?.specialValues}
            marketTypeName={event.topMarkets[0]?.marketType?.name}
            hideOutcomeName
            columnStyle={isMobile}
          />
        );
      })}
    </Box>
  );
};

export default OutcomeCells;
