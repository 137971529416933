import { Box, Stack, Typography } from '@mui/material';
import { isEmpty, merge } from 'lodash-es';
import { Outcome, SpecialValue, StyleObj } from '../../@types';
import { useBetslip } from '../../contexts/BetslipContext';
import { getOutcomeName } from '../../helpers';
import { BetDetails } from './BetDetails';
import { Lock } from '@mui/icons-material';
import OddsDisplay from './OddsDisplay';
import useLocalization from '../../hooks/useLocalization';

const makeStyles = (selected: boolean, hideOutcomeName?: boolean, disabled?: boolean): StyleObj => ({
  outcomeCell: {
    flex: 1,
    color: selected ? 'primary.600' : 'secondary.main',
    backgroundColor: selected ? 'neutral.100' : 'transparent',
    opacity: disabled ? 0.3 : 1,
    cursor: disabled ? 'not-allowed' : 'pointer',
    py: {
      xs: 1.5,
      md: 2,
    },
    px: {
      xs: 1.5,
      md: 2,
    },
    '&:hover': {
      backgroundColor: disabled ? 'neutral.800' : selected ? 'neutral.100' : 'neutral.600',
    },
    '& span': {
      typography: {
        xs: 'body2',
        md: 'body2',
      },
      letterSpacing: {
        xs: 0,
      },
    },
    display: 'flex',
    alignItems: 'center',
    minWidth: 0,
  },
  oddsStack: {
    alignItems: 'center',
    rowGap: 0.25,
    columnGap: 0.5,
    width: '100%',
  },
  lockIcon: {
    color: 'neutral.400',
    fontSize: {
      xs: '0.75rem',
      md: '1.25rem',
    },
  },
  mixedMarkets: {
    justifyContent: 'center',
    flexDirection: {
      xs: 'column',
      md: 'row',
    },
    width: '100%',
    p: {
      xs: 0.5,
      md: 0.5,
    },
    '& span': {
      typography: {
        xs: 'body4',
        md: 'body2',
      },
    },
  },
});

type OutcomeTableCellProps = {
  outcome?: Outcome | null; // optional because we want to render empty cell if there is no outcome
  disabled?: boolean;
  specialValues?: SpecialValue[];
  onClick: () => void;
  hideOutcomeName?: boolean;
  isMarketSingleDisplayType: boolean;
  marketTypeName?: string;
  sportId: string;
  mixedMarket?: boolean;
  columnStyle?: boolean;
};

const OutcomeTableCell = ({
  outcome,
  specialValues,
  onClick,
  hideOutcomeName = false, // used to hide outcome name and special values on In play page
  disabled,
  isMarketSingleDisplayType,
  marketTypeName,
  sportId,
  mixedMarket = false,
  columnStyle = false,
}: OutcomeTableCellProps) => {
  const { isOutcomeSelected } = useBetslip();
  const { getLocalizedOutcomeName } = useLocalization();

  const isEmptyCell = !outcome || outcome.odds === '1.00';

  const selected = !isEmptyCell ? isOutcomeSelected(outcome.id) : false;
  const hasSpecialValues = outcome && !isEmpty(specialValues);

  const interactionDisabled = disabled || isEmptyCell || !outcome?.isAvailable;

  const styles = makeStyles(selected, hideOutcomeName, interactionDisabled);

  const outcomeName =
    outcome && !hideOutcomeName && !hasSpecialValues ? getOutcomeName(outcome, isMarketSingleDisplayType) : null;

  const displayCentered = !isEmpty(specialValues) || hideOutcomeName || mixedMarket;

  const handleOverUnder = (outcomeName: string) => {
    let outcomeNameKey = '';
    if (outcomeName.includes('Over')) {
      outcomeNameKey = 'Over';
    } else if (outcomeName.includes('Under')) {
      outcomeNameKey = 'Under';
    }
    return ` ${getLocalizedOutcomeName(outcomeNameKey)}`;
  };

  const cellDirection = columnStyle ? 'column' : 'row';
  const cellAlignment = displayCentered ? 'center' : 'flex-start';

  if (isEmptyCell) {
    return (
      <Box
        sx={merge(styles.outcomeCell, mixedMarket && { maxHeight: '50%' })}
        flexDirection={{ xs: cellDirection, md: 'row' }}
        justifyContent={cellAlignment}
      >
        <Typography color='neutral.25' component='span' textAlign='center'>
          -
        </Typography>
      </Box>
    );
  }

  const outcomeTypographyProps = mixedMarket ? { noWrap: true, maxWidth: '100%' } : {};

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!interactionDisabled) {
      e.stopPropagation();
      onClick();
    }
  };

  return (
    <Box onClick={handleClick} sx={merge(styles.outcomeCell, mixedMarket ? styles.mixedMarkets : {})}>
      <Stack
        sx={merge(styles.oddsStack, mixedMarket ? styles.mixedMarkets : {})}
        flexDirection={{ xs: cellDirection, md: 'row' }}
        justifyContent={cellAlignment}
      >
        {hasSpecialValues && (
          <Typography
            color={selected ? 'neutral.600' : 'neutral.25'}
            component='span'
            textAlign='center'
            noWrap
            maxWidth='100%'
          >
            <BetDetails.SpecialValues
              outcomeName={outcome.name}
              sportId={sportId}
              specialValues={specialValues}
              marketTypeName={marketTypeName}
              wrapInParenthesis={false}
              numericOnly={mixedMarket}
            />
            {mixedMarket && handleOverUnder(outcome.name)}
          </Typography>
        )}
        {outcomeName && (
          <BetDetails.Outcome
            outcomeName={outcomeName}
            color={selected ? 'neutral.600' : 'neutral.25'}
            {...outcomeTypographyProps}
          />
        )}
        {interactionDisabled ? (
          <Stack alignItems='center'>
            <Lock sx={styles.lockIcon} />
          </Stack>
        ) : (
          <OddsDisplay value={outcome.odds} id={outcome.id} />
        )}
      </Stack>
    </Box>
  );
};

export default OutcomeTableCell;
