import { Box } from '@mui/material';
import { StyleObj } from '../../@types';

const makeStyles = (isHighlighted: boolean): StyleObj => ({
  indicator: {
    width: 4,
    height: 4,
    borderRadius: '50%',
    mr: 1,
    bgcolor: isHighlighted ? 'secondary.600' : 'grey.500',
  },
});

type HighlightedParticipantIndicatorProps = {
  isHighlighted: boolean;
};

const HighlightedParticipantIndicator = ({ isHighlighted }: HighlightedParticipantIndicatorProps) => {
  const styles = makeStyles(isHighlighted);

  return <Box sx={styles.indicator} />;
};

export default HighlightedParticipantIndicator;
